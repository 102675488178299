<template>
  <div class="paddingHeader anchor_tags px-5 pb-10">
    <h1 class="text-h4 primary--text text-center mt-5">
      Télépilotage de drone
    </h1>
    <v-row class="mt-1">
      <v-col
        cols="12"
        md="10"
        offset-md="1"
        class="text-subtitle-1 primary--text text-justify text-sm-center"
      >
        Chez <span class="noBreakWord font-weight-medium">Dev & Drone</span>, la
        <span class="font-weight-medium">sécurité</span> des personnes et des
        biens et le
        <span class="font-weight-medium">respect de la réglementation</span>
        sont primordiaux.
      </v-col>
    </v-row>

    <v-row class="mt-1">
      <v-col
        cols="12"
        md="10"
        offset-md="1"
        class="text-subtitle-1 primary--text text-justify text-sm-center"
      >
        Ci-dessous, les différents justificatifs donnant l'<span
          class="font-weight-medium"
          >autorisation</span
        >
        à la SASU
        <span class="noBreakWord font-weight-medium">Dev & Drone</span> et son
        télépilote Florian DÉTOURNÉ de pratiquer l'activité de télépilote
        profesionnel dans le cadre des scénarios
        <a
          @click="gotoScenarios"
          class="text-decoration-underline secondary--text"
          >S1, S2 et S3</a
        >.
      </v-col>
    </v-row>
    <v-img
      :src="require('@/assets/licence/label-operateur-autorise.png')"
      :lazy-src="require('@/assets/licence/label-operateur-autorise-lazy.png')"
      height="150"
      contain
    ></v-img>
    <v-row>
      <v-col
        cols="12"
        md="10"
        offset-md="1"
        class="text-h5 secondary--text text-center mt-5"
      >
        Les licences
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="5" offset-md="1">
        <v-img
          :src="require('@/assets/licence/theorie.jpg')"
          :lazy-src="require('@/assets/licence/theorie-lazy.jpg')"
          class="imgA4"
        ></v-img
      ></v-col>
      <v-col cols="12" sm="6" md="5"
        ><v-img
          :src="require('@/assets/licence/pratique.jpg')"
          :lazy-src="require('@/assets/licence/pratique-lazy.jpg')"
          class="imgA4"
        ></v-img
      ></v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="10"
        offset-md="1"
        class="mt-5 text-h5 secondary--text text-center"
      >
        Les déclarations auprès de la
        <span class="font-weight-medium">DGAC</span>
        <div class="text-subtitle-1">
          Direction Générale de l'Aviation Civile
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" md="5" offset-md="1"
        ><v-img
          :src="require('@/assets/licence/declaration.jpg')"
          :lazy-src="require('@/assets/licence/declaration-lazy.jpg')"
          class="imgA4"
        ></v-img
      ></v-col>
      <v-col cols="12" sm="6" md="5"
        ><v-img
          :src="require('@/assets/licence/registre.jpg')"
          :lazy-src="require('@/assets/licence/registre-lazy.jpg')"
          class="imgA4"
        ></v-img
      ></v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="10"
        offset-md="1"
        class="text-h5 secondary--text text-center mt-5"
      >
        L'assurance
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" offset-sm="3">
        <v-img
          :src="require('@/assets/licence/assurance.jpg')"
          :lazy-src="require('@/assets/licence/assurance-lazy.jpg')"
          class="imgA4"
        ></v-img
      ></v-col>
    </v-row>

    <v-row id="scenarios_de_missions">
      <v-col
        cols="12"
        md="10"
        offset-md="1"
        class="text-h5 secondary--text text-center mt-5"
      >
        Les différents scénarios de vols
      </v-col>
      <v-col cols="12" sm="8" offset-sm="2" lg="6" offset-lg="3"
        ><v-img
          class="imgScenariosMissions"
          :src="require('@/assets/licence/scenarios-de-missions.jpg')"
          :lazy-src="require('@/assets/licence/scenarios-de-missions-lazy.jpg')"
        ></v-img
      ></v-col>
    </v-row>

    <div class="text-h6 primary--text text-center mt-15">
      Intéréssé par une prestation de prises de vues aériennes ?
    </div>
    <div class="text-center mt-4">
      <v-btn class="mt-3" color="primary" text to="/drone#tarifs">
        VOIR LES TARIFS
      </v-btn>

      <v-btn class="mt-3" color="primary" text to="/drone#services">
        VOIR LES SERVICES
      </v-btn>
    </div>
    <div class="text-center mt-5">
      <v-btn color="primary" x-large to="/contact?devis">
        DEMANDER UN DEVIS
      </v-btn>
    </div>
  </div>
</template>

<script>
import goTo from "vuetify/es5/services/goto";
export default {
  components: {},
  data: () => ({}),
  metaInfo() {
    return {
      title: " - Télépilotage de drone",
      meta: [
        {
          name: "description",
          content:
            "Chez Dev & Drone, la sécurité des personnes et des biens et le respect de la réglementation sont primordiaux.",
        },
        { property: "og:image", content: "https://dev-et-drone.fr/static/og-image-drone.jpg" },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.dev-et-drone.fr/drone/prestation-telepilotage"
        },
      ],
    };
  },
  mounted() {
    //window.scrollTo(0,0);

    let imgA4List = document.querySelectorAll(".imgA4");

    for (let element of imgA4List) {
      element.style.minHeight = element.offsetWidth * (29.7 / 21) + "px";
    }

    document.querySelector(".imgScenariosMissions").style.minHeight =
      document.querySelector(".imgScenariosMissions").offsetWidth *
        (768 / 1024) +
      "px";

    setTimeout(function () {
      document.querySelector(".imgScenariosMissions").style.minHeight = null;

      for (let element of imgA4List) {
        element.style.minHeight = null;
      }
    }, 1000);
  },
  destroyed() {},
  updated() {},
  computed: {},
  methods: {
    gotoScenarios() {
      goTo(document.getElementById("scenarios_de_missions").offsetTop, {
        duration: 1000,
        offset: document.getElementsByClassName("v-toolbar__content")[0]
          .offsetHeight,
        easing: "easeInOutCubic",
      });
    },
  },
};
</script>