<template>
  <v-footer dark padless>
    <v-card
      flat
      tile
      class="primary lighten-1 white--text text-center"
      width="100%"
    >
      <v-card-text class="py-2">
        <v-btn
          v-for="socialNetwork in socialNetworks"
          :key="socialNetwork.icon"
          class="mx-4 white--text"
          icon
          :href="socialNetwork.url"
          target="_blank"
        >
          <v-icon size="24px">
            {{ socialNetwork.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-text class="white--text pb-0 pt-1">
        <router-link
          to="/politique-confidentialite"
          target="_blank"
          class="text-decoration-none white--text"
          >Politique de confidentialité</router-link
        >
        <span class="mx-2">|</span>
        <router-link
          to="/mentions-legales"
          target="_blank"
          class="text-decoration-none white--text"
          >Mentions légales</router-link
        >
      </v-card-text>

      <v-card-text class="white--text py-1">
        {{ new Date().getFullYear() }} — <strong>&copy; Dev & Drone</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>


<script>
import { mapState } from "vuex";

export default {
  data: () => ({}),
  computed: {
    ...mapState(["socialNetworks"]),
  },
};
</script>

<style scoped>
</style>