import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'


import Home from '../views/Home.vue'
import HomeDrone from '../views/drone/Home.vue'
import ServiceEvenement from '../views/drone/services/ServiceEvenement.vue'
import TODO from '../views/drone/services/ServiceEvenement.vue'
import ServiceImmobilier from '../views/drone/services/ServiceImmobilier.vue'
import ServiceEntreprise from '../views/drone/services/ServiceEntreprise.vue'
import ServiceTourisme from '../views/drone/services/ServiceTourisme.vue'
import ServiceTelepilote from '../views/drone/services/ServiceTelepilote.vue'
import HomeDeveloppement from '../views/developpement/Home.vue'
import Presentation from '../views/Presentation.vue'
import Contact from '../views/Contact.vue'
import PolitiqueConfidentialite from '../views/legal/PolitiqueConfidentialite.vue'
import MentionsLegales from '../views/legal/MentionsLegales.vue'
import NotFound from '../views/NotFound.vue'

import { mapState } from 'vuex'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/drone',
    name: 'HomeDrone',
    component: HomeDrone
  },
  {
    path: '/drone/prestation-mariage',
    name: 'mariage',
    props: { mariage: true },
    component: ServiceEvenement
  },
  {
    path: '/drone/prestation-evenement',
    name: 'evenement',
    props: { mariage: false },
    component: ServiceEvenement
  },
  {
    path: '/drone/prestation-immobilier',
    name: 'immobilier',
    component: ServiceImmobilier
  },
  {
    path: '/drone/prestation-entreprise',
    name: 'entreprise',
    component: ServiceEntreprise
  },
  {
    path: '/drone/prestation-tourisme',
    name: 'tourisme',
    component: ServiceTourisme
  },
  {
    path: '/drone/prestation-telepilotage',
    name: 'telepilotage',
    component: ServiceTelepilote
  },
  {
    path: '/developpement',
    name: 'HomeDeveloppement',
    component: HomeDeveloppement
  },
  {
    path: '/presentation',
    name: 'Presentation',
    component: Presentation
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/politique-confidentialite',
    name: 'PolitiqueConfidentialite',
    component: PolitiqueConfidentialite
  },
  {
    path: '/mentions-legales',
    name: 'MentionsLegales',
    component: MentionsLegales
  },
  {
    path: '*',
    component: NotFound,
    meta: {
      notFound: true,
    },
  },
]

var lastWindowHistoryStateKey = window.history.state == null ? null : window.history.state.key;
var isRouterScrollBehaviorActive = false;
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  isRouterScrollBehaviorActive: () => { return isRouterScrollBehaviorActive },
  scrollBehavior: (to, from, savedPosition) => {
    if (window.history.state != null && lastWindowHistoryStateKey == window.history.state.key)//si replace et non push
    {
      return;
    }
    if (to.hash != "" && document.getElementById(to.hash.replace("#", "")) == null) //si l'ancre n'existe pas
    {
      return;
    }
    lastWindowHistoryStateKey = window.history.state.key

    let scrollTo = 0
    let duration = 1000;

    if (to.hash) {
      scrollTo = to.hash
    }
    if ((to && from) && (to.name != from.name)) {
      window.scrollTo(0, 0);
      if (scrollTo == 0)
        duration = 0

      if (savedPosition) {
        scrollTo = savedPosition.y
        duration = 0
      }
    }


    isRouterScrollBehaviorActive = true
    setTimeout(function () {
      isRouterScrollBehaviorActive = false
    }, duration + 100)

    setTimeout(function () {
      goTo(scrollTo, {
        offset: document.getElementsByClassName("v-toolbar__content")[0].offsetHeight,
        easing: "easeInOutCubic",
        duration: 100
      })
    }, duration)

    return goTo(scrollTo, {
      offset: document.getElementsByClassName("v-toolbar__content")[0].offsetHeight,
      easing: "easeInOutCubic",
      duration: duration
    })
  }
})

export default router
