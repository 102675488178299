<template>
  <v-container class="mt-16">
    <div class="text-h3 primary--text text-center my-5">
      Politique de confidentialité
    </div>
    <div class="text-justify">
      <p>
        <em
          >Date de prise d’effet :
          <span class="font-weight-medium">1 septembre 2020</span></em
        >
      </p>

      <p>
        <span class="noBreakWord">Dev & Drone</span> (<span class="noBreakWord"
          ><span class="noBreakWord">« nous »</span></span
        >, <span class="noBreakWord">« notre »</span>,
        <span class="noBreakWord">« nos »)</span> exploite le site web
        https://dev-et-drone.fr (ci-après désigné par le terme
        <span class="noBreakWord">« Service »)</span>.
      </p>

      <p>
        Cette page vous explique nos politiques en matière de collecte,
        d’utilisation et de communication des données à caractère personnel
        lorsque vous utilisez notre Service ainsi que les choix qui s’offrent à
        vous en ce qui concerne ces données.
      </p>

      <p>
        Nous utilisons vos données pour fournir et améliorer le Service. En
        utilisant le Service, vous consentez à la collecte et à l’utilisation
        d’informations conformément à la présente politique.
      </p>

      <div class="secondary--text text-h5 font-weight-medium">Définitions</div>

      <ul>
        <li>
          <strong>Service</strong> : Par Service on entend le site web
          https://dev-et-drone.fr exploité par
          <span class="noBreakWord">Dev & Drone</span>
        </li>
        <li>
          <strong>Données à caractère personnel</strong> : Données à Caractère
          Personnel désigne des données concernant un individu vivant qui peut
          être identifié à partir de ces données (ou à partir de ces données et
          d’autres informations en notre possession ou susceptibles d’entrer en
          notre possession).
        </li>
        <li>
          <strong>Données d’Utilisation</strong> : Les Données d’Utilisation
          sont recueillies automatiquement et sont générées soit par
          l’utilisation du Service, soit par l’infrastructure du Service
          proprement dite (par exemple, durée de consultation d’une page).
        </li>
        <li>
          <strong>Cookies</strong> : Les cookies sont de petits fichiers
          enregistrés sur votre dispositif (ordinateur ou dispositif mobile).
        </li>
        <li>
          <strong>Responsable du Traitement</strong> : Responsable du Traitement
          désigne la personne physique ou morale (seule, ou conjointement ou en
          commun avec d’autres personnes) qui détermine les finalités et les
          modalités selon lesquelles toutes les données personnelles sont
          traitées ou doivent l’être. Aux fins de la présente Politique de
          Confidentialité, nous sommes un Responsable du Traitement de vos
          données à caractère personnel.
        </li>
        <li>
          <strong>Sous-Traitants (ou Prestataires de Services)</strong> : Le
          terme Sous-Traitant (ou Prestataire de Service) désigne toute personne
          physique ou morale qui traite les données au nom du Responsable du
          Traitement. Il se peut que nous fassions appel aux services de
          plusieurs Prestataires de Services afin de traiter vos données plus
          efficacement.
        </li>
        <li>
          <strong>Personne Concernée (ou Utilisateur)</strong> : Par Personne
          Concernée, on entend toute personne vivante qui utilise notre Service
          et est le sujet de Données à Caractère Personnel.
        </li>
      </ul>

      <div class="secondary--text text-h5 font-weight-medium">
        Collecte et utilisation des données
      </div>

      <p>
        Nous recueillons plusieurs types de données à différentes fins en vue de
        vous fournir notre Service et de l’améliorer.
      </p>

      <div class="secondary--text text-h6 font-weight-regular">
        Types de données recueillies
      </div>

      <div class="secondary--text subtitle-1">
        Données à Caractère Personnel
      </div>

      <p>
        Lorsque vous utilisez notre Service, il est possible que nous vous
        demandions de nous fournir certaines données personnelles nominatives
        qui peuvent servir à vous contacter ou à vous identifier (<span
          class="noBreakWord"
          >« Données à Caractère Personnel »</span
        >). Les données personnelles nominatives peuvent comprendre, mais de
        manière non limitative:
      </p>

      <ul>
        <li>Adresse e-mail</li>
        <li>Numéro de téléphone</li>
        <li>Prénom et nom de famille</li>
      </ul>

      <div class="secondary--text subtitle-1">Données d’Utilisation</div>

      <p>
        Nous pouvons également recueillir des informations relatives au mode
        d’accès et d’utilisation du Service (<span class="noBreakWord"
          >« Données d’Utilisation »</span
        >). Ces Données d’Utilisation peuvent comprendre des informations telles
        que l’adresse de protocole Internet (c.-à-d. l’adresse IP) de votre
        ordinateur, le type de navigateur, la version du navigateur, les pages
        de notre Service que vous consultez, la date et l’heure de votre visite,
        le temps passé sur ces pages, les identifiants uniques de dispositifs
        ainsi que d’autres données de diagnostic.
      </p>

      <div class="secondary--text subtitle-1">Suivi et données de cookies</div>

      <p>
        Nous avons recours à des cookies et à d’autres technologies de suivi
        similaires pour effectuer un suivi des activités effectuées dans notre
        Service, et nous conservons certaines informations.
      </p>

      <p>
        Les cookies sont des fichiers à faible volume de données pouvant
        comporter un identifiant unique anonyme. Les cookies sont envoyés à
        votre navigateur depuis un site web et sont stockés sur votre
        dispositif.
      </p>

      <p>
        Vous pouvez demander à votre navigateur de refuser tous les cookies ou
        de vous avertir lorsqu’un cookie est envoyé. Toutefois, si vous
        n’acceptez pas les cookies, il se peut que vous ne puissiez pas utiliser
        certains éléments de notre Service.
      </p>

      <p>Exemples de cookies que nous utilisons :</p>

      <ul>
        <li>
          Nous utilisons des Cookies de Session pour faire fonctionner notre
          Service.
        </li>
        <li>
          Nous utilisons des Cookies de Sécurité pour des raisons de sécurité.
        </li>
      </ul>

      <div class="secondary--text text-h5 font-weight-medium">
        Utilisation des données
      </div>

      <p>
        <span class="noBreakWord">Dev & Drone</span> utilise les données
        recueillies à des fins diverses:
      </p>

      <ul>
        <li>Pour fournir et assurer notre Service</li>
        <li>Pour vous faire part des changements apportés à notre Service</li>
        <li>
          Pour vous permettre d’utiliser les fonctions interactives de notre
          Service quand vous le souhaitez
        </li>
        <li>Pour assurer l’assistance client</li>
        <li>
          Pour recueillir des données précieuses ou d’analyses qui nous
          permettront d’améliorer notre Service
        </li>
        <li>Pour contrôler l’utilisation de notre Service</li>
        <li>Pour détecter, prévenir et régler les problèmes techniques</li>
      </ul>

      <p>
        <em
          >Fondement juridique du traitement des données à caractère personnel
          en vertu du Règlement Général relatif à la Protection des Données
          (RGPD)</em
        >.
      </p>

      <p>
        Si vous résidez dans l’Espace économique européen (EEE), le fondement
        juridique de <span class="noBreakWord">Dev & Drone</span> en ce qui
        concerne la collecte et l’utilisation des données personnelles décrites
        dans la présente Politique de Confidentialité dépend des Données à
        Caractère Personnel que nous recueillons et du contexte précis dans
        lequel nous les recueillons.
      </p>

      <p>
        <span class="noBreakWord">Dev & Drone</span> peut traiter vos Données à
        Caractère Personnel:
      </p>

      <ul>
        <li>
          Parce que nous avons besoin de communiquer avec vous dans le cadre
          d'une demande remplie via le formulaire de contact
        </li>
        <li>Parce que vous nous avez autorisés à le faire</li>
        <li>
          Parce que nous avons un intérêt légitime à effectuer ce traitement et
          que vos droits ne priment pas sur cet intérêt légitime
        </li>
        <li>Pour respecter la loi</li>
      </ul>

      <div class="secondary--text text-h5 font-weight-medium">
        Conservation des données
      </div>

      <p>
        <span class="noBreakWord">Dev & Drone</span> ne conservera vos Données à
        Caractère Personnel que tant que cela sera nécessaire aux fins stipulées
        dans la présente Politique de Confidentialité. Nous conserverons et
        utiliserons vos Données à Caractère Personnel dans la mesure où cela
        sera nécessaire pour que nous puissions nous acquitter de nos
        obligations légales (par exemple, pour respecter la législation en
        vigueur), résoudre des différends et appliquer nos accords et nos
        politiques.
      </p>

      <p>
        <span class="noBreakWord">Dev & Drone</span> conservera également les
        Données d’Utilisation à des fins d’analyses internes. Les Données
        d’Utilisation sont généralement conservées pour une période plus courte,
        sauf lorsque ces données sont utilisées pour renforcer la sécurité ou
        pour améliorer les fonctionnalités de notre Service, ou si nous sommes
        légalement tenus de conserver ces données plus longtemps.
      </p>

      <div class="secondary--text text-h5 font-weight-medium">
        Transfert des données
      </div>

      <p>
        Les informations vous concernant, notamment vos Données à Caractère
        Personnel, peuvent être transférées de votre région, province, pays, ou
        autre division territoriale vers des ordinateurs – et stockées sur ces
        derniers – situés à un endroit où la législation relative à la
        protection des données diffère de celle du territoire où vous résidez.
      </p>

      <p>
        Si vous résidez hors de France et que vous choisissez de nous
        communiquer des informations, sachez que nous transférons les données, y
        compris les Données à Caractère Personnel, vers la France et que nous
        les y traitons.
      </p>

      <p>
        En acceptant la présente Politique de Confidentialité puis en soumettant
        ces informations, vous consentez à ce transfert.
      </p>

      <p>
        <span class="noBreakWord">Dev & Drone</span> prendra toutes les mesures
        raisonnablement nécessaires pour faire en sorte que vos données soient
        traitées de manière sécurisée et conformément à la présente Politique de
        Confidentialité et vos Données à Caractère Personnel ne seront
        transférées vers aucune organisation ni aucun pays à moins que des
        contrôles adéquats ne soient en place, notamment en ce qui concerne la
        sécurité de vos données et d’autres données personnelles.
      </p>

      <p>
        Un accord de sous-traitance a été conclu entre
        <span class="noBreakWord">Dev & Drone</span> et IONOS SARL (hébergeur du
        site web dev-et-drone.fr) qui garanti la sécurité de vos Données à
        Caractère Personnel enregistrée sur les serveur de IONOS SARL
        conformément à la présente Politique de Confidentialité
      </p>

      <div class="secondary--text text-h5 font-weight-medium">
        Communication de données
      </div>

      <div class="secondary--text text-h6 font-weight-regular">
        Communication de données aux forces de l’ordre
      </div>

      <p>
        Dans certaines circonstances,
        <span class="noBreakWord">Dev & Drone</span> peut être tenue de
        communiquer vos Données à Caractère Personnel si la loi l’exige ou en
        réponse à des demandes valides émanant de pouvoirs publics (par ex. un
        tribunal ou un organisme gouvernemental).
      </p>

      <div class="secondary--text text-h6 font-weight-regular">
        Exigences légales
      </div>

      <p>
        <span class="noBreakWord">Dev & Drone</span> peut communiquer vos
        Données à Caractère Personnel si elle estime de bonne foi que cela est
        nécessaire pour:
      </p>

      <ul>
        <li>S’acquitter d’une obligation légale</li>
        <li>
          Protéger et défendre les droits ou les biens de
          <span class="noBreakWord">Dev & Drone</span>
        </li>
        <li>
          Prévenir d’éventuels actes répréhensibles ou enquêter sur de tels
          actes dans le cadre du Service
        </li>
        <li>
          Assurer la sécurité personnelle des utilisateurs du Service ou du
          public
        </li>
        <li>Se protéger contre la responsabilité civile</li>
      </ul>

      <div class="secondary--text text-h5 font-weight-medium">
        Sécurité des données
      </div>

      <p>
        La sécurité de vos données nous tient à cœur. Toutefois, n’oubliez pas
        qu’aucune méthode de transmission de données par Internet ou méthode de
        stockage électronique n’est sûre à 100 %. Bien que nous nous efforcions
        d’utiliser des méthodes commercialement acceptables pour protéger vos
        Données à Caractère Personnel, nous ne pouvons pas leur garantir une
        sécurité absolue.
      </p>

      <p>
        <em
          >Droits à la protection des données qui vous sont accordés par le
          Règlement Général relatif à la Protection des Données (RGPD)
        </em>
      </p>

      <p>
        Si vous résidez dans l’Espace économique européen (EEE), vous avez
        certains droits en matière de protection des données.
        <span class="noBreakWord">Dev & Drone</span> entend prendre des mesures
        raisonnables pour vous permettre de corriger, de modifier, ou de
        supprimer vos Données à Caractère Personnel ou d’en limiter
        l’utilisation.
      </p>

      <p>
        Si vous souhaitez savoir quelles Données à Caractère Personnel nous
        détenons à votre sujet et voulez qu’elles soient supprimées de nos
        systèmes, veuillez nous contacter.
      </p>

      <p>
        Dans certaines circonstances, vous avez les droits suivants en matière
        de protection des données:
      </p>

      <ul>
        <li>
          Le droit d’accéder aux informations que nous détenons à votre sujet,
          de les actualiser ou de les supprimer. Lorsque cette option est
          proposée, vous pouvez consulter ou actualiser vos Données à Caractère
          Personnel ou en demander la suppression dans la section paramètres de
          votre compte. Si vous ne pouvez pas effectuer ces actions vous-même,
          merci de nous contacter pour obtenir de l’aide.
        </li>
        <li>
          Droit de rectification. Vous avez le droit de faire rectifier vos
          données si elles sont inexactes ou incomplètes.
        </li>
        <li>
          Droit d’opposition. Vous avez le droit de vous opposer à ce que nous
          traitions vos Données à Caractère Personnel.
        </li>
        <li>
          Droit de limitation Vous avez le droit de nous demander de limiter le
          traitement de vos données personnelles.
        </li>
        <li>
          Le droit à la portabilité des données. Vous avez le droit de recevoir
          une copie des informations que nous détenons à votre sujet dans un
          format couramment utilisé, structuré et lisible par une machine.
        </li>
        <li>
          Droit au retrait du consentement. Vous avez également le droit de
          retirer votre consentement à tout moment si
          <span class="noBreakWord">Dev & Drone</span> s’est appuyé sur votre
          consentement pour traiter vos données personnelles.
        </li>
      </ul>

      <p>
        Veuillez noter que nous pouvons vous demander de prouver votre identité
        avant de répondre à des demandes de cette nature.
      </p>

      <p>
        Vous avez le droit de déposer plainte auprès d’une autorité de
        protection des données au sujet de notre collecte et de notre
        utilisation de vos Données à Caractère Personnel. Pour plus de
        précisions, veuillez contacter l’autorité de protection des données la
        plus proche de vous dans l’Espace économique européen (EEE).
      </p>

      <div class="secondary--text text-h5 font-weight-medium">
        Prestataires de services
      </div>

      <p>
        Nous pouvons faire appel à des sociétés tierces et à des tierces
        personnes pour faciliter la prestation de notre Service (<span
          class="noBreakWord"
          >« Prestataires de Services »</span
        >), assurer le Service en notre nom, assurer des services liés au
        Service ou nous aider à analyser la façon dont notre Service est
        utilisé.
      </p>

      <p>
        Ces tiers n’ont accès à vos Données à Caractère Personnel que pour
        effectuer ces tâches en notre nom et il leur est interdit de les
        communiquer ou de les utiliser à quelle qu’autre fin.
      </p>

      <p>
        Ce site web utilise les services d’analyse de IONOS Web Analytics. Le
        fournisseur est la société IONOS SARL, 7, place de la Gare - 57200
        SARREGUEMINES. Les analyses réalisées avec IONOS peuvent notamment
        comprendre l’analyse du nombre et du comportement des utilisateurs (par
        exemple nombre de consultations d’une page, durée d’une visite du site
        web, taux de rebond), des sources des utilisateurs (c’est-à-dire les
        pages d’où viennent les utilisateurs), de la localisation des
        utilisateurs ainsi que des données techniques (versions du navigateur et
        du système d’exploitation). À cette fin, IONOS enregistre en particulier
        les données suivantes :
      </p>
      <ul>
        <li>page référente (page web précédemment visitée)</li>
        <li>page web ou fichier consultés</li>
        <li>type et version du navigateur</li>
        <li>système d’exploitation utilisé</li>
        <li>type d’appareil utilisé</li>
        <li>heure d’accès</li>
        <li>
          adresse IP sous forme anonymisée (uniquement utilisée pour déterminer
          la localisation de l’accès)
        </li>
      </ul>
      <p>
        Selon IONOS, la collecte de données s’effectue de manière entièrement
        anonymisée de sorte qu’il est impossible de retracer une personne
        individuelle. IONOS Web Analytics n’enregistre pas de cookies.
      </p>

      <p>
        L’enregistrement et l’analyse de ces données s’effectue sur la base de
        l’art. 6 al. 1 point f du RGPD. L’exploitant du site web a un intérêt
        légitime à l’analyse statistique du comportement des utilisateurs afin
        d’optimiser son offre web comme sa publicité. Vous trouverez plus
        d’informations sur la collecte et le traitement des données par IONOS
        Web Analytics via les liens suivants :
      </p>
      <ul>
        <li>
          <a href="https://www.ionos.fr/assistance/marketing-en-ligne/"
            >https://www.ionos.fr/assistance/marketing-en-ligne/</a
          >
        </li>

        <li>
          <a
            href="https://www.ionos.fr/assistance/protection-des-donnees/traitement-des-donnees-des-visiteurs-du-site-web-de-votre-produit-11-ionos/webstat/"
            >https://www.ionos.fr/assistance/protection-des-donnees/traitement-des-donnees-des-visiteurs-du-site-web-de-votre-produit-11-ionos/webstat/</a
          >
        </li>

        <li>
          <a href="https://www.ionos.fr/terms-gtc/terms-privacy/"
            >https://www.ionos.fr/terms-gtc/terms-privacy/</a
          >
        </li>
      </ul>

      <!--<div class="secondary--text text-h6 font-weight-regular">Remarketing comportemental</div>

      <p>
        <span class="noBreakWord">Dev & Drone</span> fait appel à des services de remarketing pour vous présenter
        des annonces sur des sites de tiers après que vous ayez consulté notre
        Service. Nous et nos fournisseurs tiers utilisons des cookies pour
        façonner, optimiser et diffuser des annonces en fonction de votre
        fréquentation antérieure de notre Service.
      </p>

      <ul>
        <li>
          <strong>Facebook</strong> : Le service de remarketing Facebook est
          fourni par Facebook Inc. Vous pouvez en savoir plus sur les publicités
          Facebook basées sur les centres d’intérêt en consultant
          <a
            rel="noreferrer noopener"
            aria-label="cette page (opens in a new tab)"
            href="https://www.facebook.com/help/164968693837950"
            target="_blank"
            >cette page</a
          >, pour refuser les publicités Facebook basées sur les centres
          d’intérêt,
          <a
            rel="noreferrer noopener"
            aria-label="suivez ces instructions de Facebook (opens in a new tab)"
            href="https://www.facebook.com/help/568137493302217"
            target="_blank"
            >suivez ces instructions de Facebook</a
          >. Facebook respecte les Principes d’autorégulation en matière de
          publicité comportementale en ligne établis par la Digital Advertising
          Alliance. Vous pouvez aussi refuser Facebook et d’autres sociétés
          participantes par le biais de
          <a
            rel="noreferrer noopener"
            aria-label="Digital Advertising Alliance aux États-Unis (opens in a new tab)"
            href="http://www.aboutads.info/choices/"
            target="_blank"
            >Digital Advertising Alliance aux États-Unis</a
          >, de
          <a
            rel="noreferrer noopener"
            aria-label="Digital Advertising Alliance of
 Canada au Canada (opens in a new tab)"
            href="http://youradchoices.ca/"
            target="_blank"
            >Digital Advertising Alliance of Canada au Canada</a
          >
          ou de
          <a
            rel="noreferrer noopener"
            aria-label="European Interactive
 Digital Advertising Alliance en Europ (opens in a new tab)"
            href="http://www.youronlinechoices.eu/"
            target="_blank"
            >European Interactive Digital Advertising Alliance en Europe</a
          >, ou en utilisant les paramètres de votre dispositif mobile. Pour
          plus de précisions sur les pratiques de confidentialité de Facebook,
          merci de
          <a
            rel="noreferrer noopener"
            aria-label="consulter la politique de Facebook (opens in a new tab)"
            href="https://www.facebook.com/privacy/explanation"
            target="_blank"
            >consulter la politique de Facebook</a
          >
          en matière de données.
        </li>
      </ul>-->

      <!--<div class="secondary--text text-h6 font-weight-regular">Paiements</div>

      <p>
        Il se peut que nous proposions des produits et/ ou services payants dans
        le cadre du Service. En pareil cas, nous ferons appel à des services de
        tiers pour le traitement des paiements (c.-à-d. à des sociétés de
        traitement des paiements).
      </p>

      <p>
        Nous ne recueillerons pas et ne conserverons pas les données de votre
        carte de paiement. Ces données sont directement communiquées aux
        sociétés tierces qui traitent les paiements, lesquelles sociétés
        utilisent vos données personnelles conformément à leur politique de
        confidentialité. Ces sociétés de traitement des paiements respectent la
        norme PCI-DSS gérée par le PCI Security Standards Council, qui est le
        fruit d’un effort concerté entre des marques telles que Visa,
        MasterCard, American Express et Discover. Les exigences PCI-DSS aident à
        garantir un traitement sécurisé des informations de paiement. Les
        sociétés de traitement de paiements avec lesquelles nous travaillons
        sont:
      </p>

      <ul>
        <li>
          <strong>Stripe</strong> : Leur
          <a
            rel="noreferrer noopener"
            aria-label="politique de confidentialité (opens in a new tab)"
            href="https://stripe.com/us/privacy"
            target="_blank"
            >politique de confidentialité</a
          >
          est disponible sur le site ;
        </li>
        <li>
          <strong>PayPal</strong> : Leur
          <a
            rel="noreferrer noopener"
            aria-label="politique de confidentialité (opens in a new tab)"
            href="https://www.paypal.com/webapps/mpp/ua/privacy-full"
            target="_blank"
            >politique de confidentialité</a
          >
          est disponible sur le site.
        </li>
      </ul>-->

      <div class="secondary--text text-h5 font-weight-medium">
        Liens pointant vers d’autres sites
      </div>

      <p>
        Il se peut que notre Service contienne des liens pointant vers d’autres
        sites que nous n’exploitons pas. Si vous cliquez sur un lien de tiers,
        vous serez redirigé vers le site de ce tiers. Nous vous recommandons
        vivement d’examiner la politique de confidentialité de chacun des sites
        que vous consultez.
      </p>

      <p>
        Nous n’avons aucun contrôle sur le contenu, les politiques ou pratiques
        de confidentialité des sites ou services de tiers et déclinons toute
        responsabilité en ce qui les concerne.
      </p>

      <div class="secondary--text text-h5 font-weight-medium">
        Vie privée des enfants
      </div>

      <p>
        Notre Service ne s’adresse pas aux personnes de moins de 18 ans (<span
          class="noBreakWord"
          >« Enfants »</span
        >).
      </p>

      <p>
        Nous ne recueillons pas sciemment de données personnelles nominatives
        auprès de personnes de moins de 18 ans. Si vous êtes un parent ou un
        tuteur et que vous savez que votre Enfant nous a communiqué des Données
        à Caractère Personnel, veuillez nous contacter. Si nous apprenons que
        nous avons recueilli des Données à Caractère Personnel auprès d’enfants
        sans vérifier s’il y a consentement parental, nous faisons le nécessaire
        pour supprimer ces informations de nos serveurs.
      </p>

      <div class="secondary--text text-h5 font-weight-medium">
        Modifications de la présente Politique de Confidentialité
      </div>

      <p>
        Nous nous réservons le droit d’actualiser notre Politique de
        Confidentialité de temps à autre. Nous vous informerons de toute
        modification en publiant la nouvelle Politique de Confidentialité sur
        cette page.
      </p>

      <p>
        Avant que la modification ne prenne effet, nous vous en informerons par
        e-mail et/ ou en plaçant un avis bien en vue dans notre Service et nous
        actualiserons la
        <span class="noBreakWord">« date de prise d’effet »</span> qui figure en
        haut de la présente Politique de Confidentialité.
      </p>

      <p>
        Nous vous conseillons de consulter la présente Politique de
        Confidentialité périodiquement pour prendre connaissance de toute
        modification. Les modifications apportées à la présente Politique de
        Confidentialité prennent effet lorsqu’elles sont publiées sur cette
        page.
      </p>

      <div class="secondary--text text-h5 font-weight-medium">
        Nous contacter
      </div>

      <p>
        Pour toute question relative à la présente Politique de Confidentialité,
        veuillez nous contacter:
      </p>

      <ul>
        <li>
          Par courrier électronique:
          <a href="mailto:donnees-personnelles@dev-et-drone.fr"
            >donnees-personnelles@dev-et-drone.fr</a
          >
        </li>
      </ul>
    </div>
  </v-container>
</template>

<script>

export default {
  name: "PolitiqueConfidentialite",
  metaInfo() {
    return {
      title: " - Politique de Confidentialité",
    };
  },
};
</script>

<style scoped>
ul {
  margin-bottom: 16px;
}
</style>