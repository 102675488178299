<template>
  <div id="formulaires">
    <v-card elevation="3">
      <v-row>
        <v-col cols="12">
          <!--<v-card-title class="text-center justify-center py-6">
			<h1 class="text-h4 primary--text">
				Formulaire
			</h1>
		</v-card-title>-->

          <v-tabs v-model="tab" grow centered icons-and-text show-arrows>
            <v-tab key="FormulaireInfo" :disabled="displaySuccess">
              Demande d'informations
              <v-icon>mdi-information-variant</v-icon>
            </v-tab>
            <v-tab key="FormulaireDevis" :disabled="displaySuccess">
              Demande de devis
              <v-icon>mdi-file-eye-outline</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item key="FormulaireInfo">
              <v-card flat>
                <FormulaireInfo
                  @submit="submit"
                  :sendingForm="sendingForm"
                  :formSend="formSend"
                  v-show="!displaySuccess"
                />
              </v-card>
            </v-tab-item>
            <v-tab-item key="FormulaireDevis">
              <v-card flat>
                <FormulaireDevis
                  @submit="submit"
                  :sendingForm="sendingForm"
                  :formSend="formSend"
                  v-show="!displaySuccess"
                />
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <div id="messageErreur" class="mx-6" v-show="message_erreur != ''">
            <v-alert :prominent="prominentAlert" outlined type="error">
              <span v-html="message_erreur"></span>
            </v-alert>
          </div>
          <div class="mx-6" v-show="displaySuccess">
            <v-alert
              :value="displaySuccess"
              class="my-10"
              type="success"
              icon="mdi-check"
              outlined
              prominent
              transition="scale-transition"
            >
              Votre demande a bien été envoyée. Je vous répondrais dans 48h
              (hors week-end).
            </v-alert>
          </div>
        </v-col>
      </v-row>

      <v-row class="mt-0 text-center">
        <v-col cols="12" class="px-10 pt-0">
          <small><MentionRGPD /></small>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import FormulaireDevis from "@/components/formulaires/FormulaireDevis.vue";
import FormulaireInfo from "@/components/formulaires/FormulaireInfo.vue";
import MentionRGPD from "@/components/legal/MentionRGPD.vue";
import { mapState } from "vuex";
import goTo from "vuetify/es5/services/goto";

export default {
  name: "Formulaires",
  components: {
    FormulaireDevis,
    FormulaireInfo,
    MentionRGPD,
  },
  data() {
    return {
      tab: 0,
      message_erreur: "",
      displaySuccess: false,
      sendingForm: false,
      formSend: false,
      prominentAlert: false,
    };
  },
  computed: {
    ...mapState(["urlApiFormulaire"]),
  },
  mounted() {
    if (window.location.search == "?devis") {
      console.log(document.getElementById("formulaires").offsetTop);
      this.tab = 1;
      setTimeout(function () {
        goTo(document.getElementById("formulaires").offsetTop, {
          duration: 1000,
          offset:
            document.getElementsByClassName("v-toolbar__content")[0]
              .offsetHeight + 20,
          easing: "easeInOutCubic",
        });
      }, 1);
    }
  },
  methods: {
    submit(payload) {
      let formulaire = payload.formulaire;
      let type = payload.type;

      let vm = this;
      this.sendingForm = true;

      let data = new FormData();
      data.append("formulaire", JSON.stringify(formulaire));
      data.append("type", type);

      fetch(this.urlApiFormulaire, {
        //mode: "cors",
        /*headers: {
          Accept: "application/json",
          "Content-Type": "text/plain; charset=UTF-8"
        },*/
        method: "POST",
        body: data,
      })
        .then((response) => {
          vm.sendingForm = false;
          if (response.status >= 200 && response.status < 300) {
            vm.message_erreur = "";
            vm.formSend = true;

            setTimeout(function () {
              vm.displaySuccess = true;
            }, 1);
          } else if (response.status === 400) {
            response.json().then(function (data) {
              vm.message_erreur = "<ul>";
              for (let i = 0; i < data.length; i++) {
                vm.message_erreur += "<li>" + data[i] + "</li>";
              }
              vm.message_erreur += "</ul>";

              if (data.length == 1) vm.prominentAlert = false;
              else vm.prominentAlert = true;
              vm.scrollToAlert();
            });
            //let errorResponse = await res.json();
            //vm.message_erreur = "errorResponse.error;"
          } else {
            vm.message_erreur =
              "Une erreur s'est produite, veuillez réessayer ultérieurement.";
          }
        })
        .catch(function () {
          vm.sendingForm = false;
          vm.message_erreur =
            "Une erreur s'est produite, veuillez réessayer ultérieurement.";
        });
    },
    scrollToAlert() {
      setTimeout(function () {
        let scrollManquant =
          document.getElementById("messageErreur").getBoundingClientRect().top +
          document.getElementById("messageErreur").offsetHeight -
          window.innerHeight +
          20;
        if (scrollManquant > 0) {
          goTo(window.scrollY + scrollManquant, {
            duration: 1000,
            offset: 0,
            easing: "easeInOutCubic",
          });
        }
      }, 100);
    },
  },
};
</script>