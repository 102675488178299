import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#254460',
				secondary: '#3A6C90',
				accent: '#C40000',
				error: '#FF5252',
				info: '#2196F3',
				success: '#337936',
				warning: '#FB8C00',
			},
		},
	}
});
