<template>
  <v-app>
    <AppNavigation />

    <v-main transition="slide-x-transition" style="padding-top: 0">
      <router-view></router-view>
    </v-main>

    <Footer v-if="!isHome" />
  </v-app>
</template>


<script>
import AppNavigation from "@/components/AppNavigation.vue";
import Footer from "@/components/Footer.vue";
import router from "@/router";

import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    AppNavigation,
    Footer,
  },
  metaInfo() {
    return {
      title: "",
      titleTemplate: this.appTitle + "%s",
      meta: [
        {
          name: "description",
          content:
            "Dev & Drone est une entreprise proposant des prises de vues aériennes en drone mais aussi de la prestation pour les entreprises dans le développement web. Le siège est basé sur Nantes mais le déplacement est possible en Pays de la Loire et dans toute la France.",
        },
        { property: "og:site_name", content: "Dev & Drone" },
        { property: "og:type", content: "website" },
        {
          property: "og:image",
          content: "https://dev-et-drone.fr/static/og-image-logo.jpg",
        },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  created() {},
  mounted() {
    document
      .querySelector(":root")
      .style.setProperty("--vh", window.innerHeight / 100 + "px");

    window.addEventListener("resize", () => {
      document
        .querySelector(":root")
        .style.setProperty("--vh", window.innerHeight / 100 + "px");
    });

    let vm = this;
    setTimeout(function () {
      vm.scrollFade();
    }, 1);

    this.fadeInElements = Array.from(
      document.getElementsByClassName("fade-in")
    );
    document.addEventListener("scroll", this.scrollFade);

    // FOR PRERENDER.IO
    if (!window.location.search.includes("prerenderIO")) {
      for (let i = 0; i < this.fadeInElements.length; i++) {
        var elem = this.fadeInElements[i];
        elem.classList.add("fade-in-done");
        elem.style.opacity = "1";
        elem.style.transform = "none";
      }
    }
  },
  beforeUpdate() {
    document.removeEventListener("scroll", this.scrollFade);
  },
  updated() {
    let vm = this;

    setTimeout(function () {
      document.addEventListener("scroll", vm.scrollFade);
      vm.scrollFade();
    }, 1);

    this.fadeInElements = Array.from(
      document.getElementsByClassName("fade-in")
    );

    document.removeEventListener("scroll", this.scrollAnchor);
    setTimeout(function () {
      document.addEventListener("scroll", vm.scrollAnchor);
    }, 1200);
  },
  destroyed() {
    document.removeEventListener("scroll", this.scrollFade);
  },
  computed: {
    ...mapState(["appTitle"]),
    isHome() {
      return this.$route.name === "Home";
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    getProperty__vh() {
      return document.querySelector(":root").style.getProperty("--vh");
    },
  },
  watch: {
    $route(to, from) {
      //window.scrollTo(0,0);
    },
  },
  methods: {
    scrollFade() {
      for (let i = 0; i < this.fadeInElements.length; i++) {
        const elem = this.fadeInElements[i];
        if (
          !elem.classList.contains("fade-in-done") &&
          this.isElemVisible(elem)
        ) {
          elem.classList.add("fade-in-done");
          elem.style.opacity = "1";
          elem.style.transform = "none";
        }
      }
    },
    isElemVisible(elem) {
      let styleTransform = getComputedStyle(elem)
        .transform.replace(/matrix|\(|\)/g, "")
        .split(",");
      let transformY = parseInt(styleTransform[5]);
      transformY = isNaN(transformY) ? 0 : transformY;
      const rect = elem.getBoundingClientRect();
      let buffer = window.innerHeight * 0.1;
      const elemTop = rect.top + buffer - transformY; /*buffer*/
      const elemBottom = rect.bottom;
      return elemTop < window.innerHeight && elemBottom >= 0;
    },
    scrollAnchor() {
      document.getElementsByClassName("anchor_tags").forEach((item, index) => {
        if (router.options.isRouterScrollBehaviorActive()) return;
        var top = window.pageYOffset;
        var distance = top - item.offsetTop;
        var hash = item.id;
        // if you want a precise check then use distance===0
        var paddingChoice = window.innerHeight / 2;
        if (
          distance < paddingChoice &&
          distance > -paddingChoice &&
          router.currentRoute.hash != (hash ? "#" : "") + hash
        ) {
          if (hash == "") {
            router.replace(window.location.pathname);
            //window.location = window.location.origin + window.location.pathname;
            //window.location.href = window.location.pathname;
            // window.location.hash = hash;
          } else {
            router.replace(window.location.pathname + "#" + hash);
          }
        }
      });
    },
  },

  data: () => ({
    fadeInElements: [],
  }),
};
</script>

<style>
html {
  overflow-y: auto;
}

.flexParentCenterCenter {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.noBreakWord {
  word-break: keep-all;
  display: inline-block;
}

.marginHeader {
  margin-top: 64px;
}

.paddingHeader {
  padding-top: 64px;
}

.fade-in {
  opacity: 0;
  transition: 0.8s all ease-out;
}

.fade-in.translate-right {
  transform: translateX(100px);
}

.fade-in.translate-full-right {
  transform: translateX(100vw);
}

.fade-in.translate-left {
  transform: translateX(-100px);
}

.fade-in.translate-full-left {
  transform: translateX(-100vw);
}

.fade-in.translate-bottom {
  transform: translateY(100px);
}

.fade-in.translate-top {
  transform: translateY(-100px);
}

.fade-in.scale-enlarge {
  transform: scale(0.8);
}

.fade-in.scale-shrink {
  transform: scale(1.2);
}

@media screen and (max-width: 959px) {
  .marginHeader {
    margin-top: 56px;
  }
  .paddingHeader {
    padding-top: 56px;
  }
}
</style>
