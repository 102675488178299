<template>
  <Univers />
</template>

<script>
// @ is an alias to /src
import Univers from "@/components/Univers.vue";


export default {
  name: "Home",
  metaInfo() {
    return {
      title: " - Voyez plus loin !",
      link: [
        {
          rel: "canonical",
          href: "https://www.dev-et-drone.fr"
        },
      ],
    };
  },
  mounted: function () {},
  components: {
    Univers,
  },
};
</script>
