import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appTitle: "Dev & Drone",
    socialNetworks: [
      { icon: "mdi-youtube", color: "accent", url: "https://www.youtube.com/channel/UCV8mojjJjrqlm5ehNC5yqrg" },
      { icon: "mdi-facebook", color: "primary", url: "https://fb.me/devetdrone" },
      { icon: "mdi-instagram", color: "accent", url: "https://www.instagram.com/devetdrone" }
    ],
    urlApiFormulaire: "https://dev-et-drone.fr/PHP/formulaire.php",
  },
  getters: {
    isMobile: () => {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
