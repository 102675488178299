<template>
  <div class="marginHeader px-5">
    <v-row>
      <v-col class="mt-10 mb-5 rounded" id="colFadeInWriter">
        <div
          id="fadeInWriter_1"
          class="
            fontCustom
            fadeInWriter
            primary--text
            text-h6 text-sm-h5 text-md-h4
            flexParentCenterCenter
          "
        >
          <div
            v-for="(text, index) in words_1"
            :key="index"
            :id="'words_1_' + index"
            :class="'word' + (index == 0 ? ' active' : '')"
            v-html="text"
          ></div>
          <div style="opacity: 0">.</div>
        </div>
        <div
          id="fadeInWriter_2"
          class="
            fontCustom
            fadeInWriter
            primary--text
            text-h6 text-sm-h5 text-md-h4
            flexParentCenterCenter
            mt-1
          "
        >
          <div
            v-for="(text, index) in words_2"
            :key="index"
            :id="'words_2_' + index"
            :class="'word' + (index == 0 ? ' active' : '')"
          >
            {{ text }}
          </div>
          <div style="opacity: 0">.</div>
        </div>
        <div
          id="fadeInWriter_3"
          class="
            fadeInWriter
            fontCustom
            primary--text
            text-center
            mt-5
            text-h6 text-sm-h5 text-md-h4
          "
          style="transform: scale(0.8)"
        >
          CHOISISSEZ LE
          <span class="noBreakWord"
            ><span class="font-weight-medium">DRONE</span> !</span
          >
        </div>
      </v-col>
    </v-row>
    <v-container>
      <v-row class="fade-in translate-bottom">
        <v-col cols="12" lg="10" offset-lg="1">
          <div
            class="
              secondary--text
              my-5
              fontCustom
              titleCustom
              text-h6 text-sm-h5 text-md-h4
            "
          >
            Qu'est-ce que cela <span class="noBreakWord">apporte ?</span>
          </div>

          <v-row>
            <v-col cols="12" sm="12" md="8" lg="8">
              <div class="secondary--text text-subtitle-1 text-justify">
                Le drone offre un moyen de
                <span class="font-weight-medium"
                  >communication efficace et inovant</span
                >
                pour valoriser votre activité ! Le drone donne la possibilité de
                capturer une zone sous des
                <span class="font-weight-medium">angles uniques</span> qui sont
                accessibles seulement avec un drone. Cela permet de
                <span class="font-weight-medium">prendre de la hauteur</span>
                et, par exemple de découvrir l'horizon en franchissant le sommet
                d'un bâtiment.
              </div>
              <div class="secondary--text text-subtitle-1 text-justify mt-3">
                <span class="font-weight-medium">En intérieur</span>, dans un
                hangar, il est possible d'utiliser le drone pour avoir une vue
                d'ensemble qui peut montrer l'étendue de votre site.
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="4"
              lg="4"
              class="flexParentCenterCenter"
            >
              <video
                width="100%"
                autoplay
                muted
                loop
                disableRemotePlayback
                :poster="require('@/assets/immobilier/grandville_loop.jpg')"
              >
                <source
                  :src="require('@/assets/immobilier/grandville_loop_480.mp4')"
                  type="video/mp4"
                />
              </video>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="fade-in translate-bottom">
        <v-col cols="12" lg="10" offset-lg="1">
          <div
            class="
              secondary--text
              mt-5
              fontCustom
              titleCustom
              text-h6 text-sm-h5 text-md-h4
            "
          >
            Quelles sont les limites ?
          </div>
          <div class="secondary--text text-subtitle-1 text-justify mt-5">
            La réglementation française et européenne est stricte pour permettre
            à ce que les drones évoluent toujours en sécurité. Certaines zones
            de vols imposent le télépilote à avoir une dérogation (démarches
            administratives faites en amont) comme les agglomérations, les zones
            à proximité d'un aéroport ou d'une zone militaire.

            <div class="text-center mt-2">
              <v-btn
                small
                text
                to="/drone/prestation-telepilotage"
                class="text--secondary"
              >
                Plus d'informations ici
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="fade-in translate-bottom">
        <v-col cols="12" lg="10" offset-lg="1">
          <v-row>
            <v-col
              offset-md="4"
              offset-lg="4"
              class="
                secondary--text
                mt-5
                mb-2
                py-0
                fontCustom
                titleCustom
                text-h6 text-sm-h5 text-md-h4
              "
            >
              Et la <span class="noBreakWord">météo ?</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="4" order="last" order-md="first">
              <v-img
                :src="require('@/assets/drone/DJI_0033.jpg')"
                :lazy-src="require('@/assets/drone/DJI_0033-lazy.jpg')"
              >
              </v-img>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="8"
              order="first"
              order-md="last"
              class="flexParentCenterCenter"
            >
              <div>
                <div class="secondary--text text-subtitle-1 text-justify">
                  S'il pleut ou s'il y a du vent supérieur à 40km/h, le vol est
                  impossible pour des questions de sécurité. Si tel est le cas
                  et si cela est possible, le tournage sera décalé à une
                  nouvelle date.
                </div>
                <div class="secondary--text text-subtitle-1 text-justify mt-3">
                  Les vols de nuits doivent obligatoirement faire l'objet d'une
                  autorisation préalable par les autorités. Le drone peut voler
                  sans autorisation préalable 30 minutes avant le lever du
                  soleil et 30 minutes après le lever du soleil.
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="fade-in translate-bottom">
        <v-col cols="12" lg="10" offset-lg="1">
          <div
            class="
              secondary--text
              mt-5
              fontCustom
              titleCustom
              text-h6 text-sm-h5 text-md-h4
            "
          >
            L'expérience vous <span class="noBreakWord">tente ?</span>
          </div>
          <div class="secondary--text text-subtitle-1 text-justify mt-5">
            <ul>
              <li>
                Grâce au formulaire de demande de devis, présentez votre projet
                en quelques clics et quelques mots.
              </li>
              <li>
                Je reviendrais ensuite vers vous en vous proposant un devis
                après avoir vérifié que les zones de vols sont autorisées ou si
                une dérogation est possible.
              </li>
              <li>Un repérage en amont peut être effectué si besoin.</li>
              <li>
                Le jour du tournage, le télépilote effectuera les prises de vues
                demandées et vous aurez accès au retour vidéo. Un périmètre de
                sécurité pourra être mis en place si besoin.
              </li>
              <li>
                Ensuite, je peux m'occuper de la post-production et réaliser une
                vidéo de présentation.
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>
      <v-row class="fade-in translate-bottom">
        <v-col cols="12" lg="10" offset-lg="1">
          <div
            class="
              secondary--text
              mt-5
              fontCustom
              titleCustom
              text-h6 text-sm-h5 text-md-h4
            "
          >
            Quels sont les <span class="noBreakWord">tarifs ?</span>
          </div>
          <div class="secondary--text text-subtitle-1 text-justify mt-5">
            <div>
              Votre entreprise est
              <span class="font-weight-medium">unique</span> et par conséquent,
              le devis sera fait sur-mesure et je vous proposerai le
              <span class="font-weight-medium">meilleur tarif</span> qui
              conviendra à votre besoin. N'hésitez pas à me contacter si vous
              avez des questions, je suis à votre écoute.
            </div>
            <div class="text-center mt-8 mb-5">
              <v-btn color="primary" x-large to="/contact?devis">
                DEMANDER UN DEVIS
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import goTo from "vuetify/es5/services/goto";

export default {
  components: {},
  metaInfo() {
    return {
      title: " - Services drone aux entreprises",
      meta: [
        {
          name: "description",
          content:
            "Dev & Drone propose des prises de vues aériennes pour les entreprises que ce soit de la vidéo de promotion et de présentation ou de la vidéo d'inspection d'ouvrage.",
        },
        { property: "og:image", content: "https://dev-et-drone.fr/static/og-image-immobilier.jpg" },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.dev-et-drone.fr/drone/prestation-entreprise"
        },
      ],
    };
  },
  data: () => ({
    listInterval: [],
    words_1: [
      "UN SITE DE PRODUCTION",
      "UN HANGAR",
      "UN ENSEMBLE DE BÂTIMENTS",
      "UNE CONSTRUCTION",
      "<span class='font-weight-medium'>VOTRE ENTREPRISE</span>",
    ],
    words_2: [
      "À METTRE EN VALEUR ?",
      "À PROMOUVOIR ?",
      "À PRÉSENTER ?",
      "A VISITER ?",
    ],
  }),
  mounted() {
    let pauseDuration = 4000;
    let transitionDuration = 500;
    let fadeInWriter_1 = document.querySelectorAll("#fadeInWriter_1 .word");
    let fadeInWriter_2 = document.querySelectorAll("#fadeInWriter_2 .word");
    let fadeInWriter_3 = document.querySelectorAll("#fadeInWriter_3 .word");
    let vm = this;

    this.listInterval.push(
      setInterval(function () {
        vm.setFadeInWriter(fadeInWriter_1, pauseDuration, transitionDuration);
      }, pauseDuration + transitionDuration)
    );
    setTimeout(function () {
      vm.listInterval.push(
        setInterval(function () {
          vm.setFadeInWriter(fadeInWriter_2, pauseDuration, transitionDuration);
        }, pauseDuration + transitionDuration)
      );
    }, 2000);
  },
  destroyed() {
    for (let interval in this.listInterval) {
      clearInterval(interval);
    }
  },
  updated() {},
  methods: {
    setFadeInWriter(fadeInWriter, pauseDuration, transitionDuration) {
      let element, index;
      fadeInWriter.forEach((element, index) => {
        if (element.classList.contains("active")) {
          element.classList.remove("active");
          setTimeout(function () {
            let nextIndex = index == fadeInWriter.length - 1 ? 0 : index + 1;
            fadeInWriter[nextIndex].classList.add("active");
          }, transitionDuration);
        }
      });
    },
  },
};
</script>

<style scoped>
/*@import url("https://fonts.googleapis.com/css?family=Walter+Turncoat");*/

.fadeInWriter .word {
  position: absolute;
  opacity: 0;
  transition: 1s all ease-in-out;
}

.fadeInWriter .word.active {
  opacity: 1 !important;
}

/*.fontCustom {
  font-family: "Walter Turncoat", sans-serif !important;
}*/

.fontCustom.titleCustom {
  /*H4*/
  transform: scale(0.9);
  transform-origin: left;
}

.v-image {
  max-height: none;
  min-height: none;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .v-image {
    max-height: 300px;
    min-height: 200px;
  }
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  .v-image {
    height: 350px;
  }
}

@media screen and (min-width: 959px) and (max-width: 1263px) {
}

@media screen and (min-width: 1263px) and (max-width: 1903px) {
}
</style>