<template>
  <v-container id="realisations" class="anchor_tags">
    <h2
      class="text-h3 primary--text text-xs-center my-5 fade-in translate-left"
    >
      Réalisations
    </h2>
    <div class="text-justify">
      Les développements déjà réalisés par
      <span class="font-weight-medium noBreakWord">Dev & Drone</span> pour les
      partenaires ci-dessous sont pour la plupart des développements en interne et donc des développements privés. Certains développements sont publics et donc accesibles.
    </div>
    <ListeRealisations class="my-10" />
    <div>
      <div class="text-center secondary--text text-h5 mt-5 mb-10">
        Ces partenaires m'ont fait confiance !
      </div>
      <v-row class="flexParentCenterCenter">
        <v-col
          v-for="(partenaire, index) in partenaires"
          :key="index"
          cols="6"
          sm="3"
          style="height: 124px"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                class=""
                height="100"
                contain
                :src="partenaire.src"
                :lazy-src="partenaire.lazy"
                v-bind="attrs"
                v-on="on"
              >
              </v-img>
            </template>
            <span>{{ partenaire.nom }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import ListeRealisations from "@/components/developpement/ListeRealisations.vue";

export default {
  components: {
    ListeRealisations,
  },
  name: "Realisations",

  data: () => ({
    partenaires: [
      {
        nom: "Crédit Agricole Atlantique Vendée",
        src: require("@/assets/developpement/partenaires/caav.jpg"),
        lazy: require("@/assets/developpement/partenaires/caav-lazy.jpg"),
      },
      {
        nom: "Gaz Réseau Distribution France",
        src: require("@/assets/developpement/partenaires/grdf.jpg"),
        lazy: require("@/assets/developpement/partenaires/grdf-lazy.jpg"),
      },
    ],
  }),
  created() {
    this.show = true;
  },
  mounted() {},
  destroyed() {},
  methods: {},
};
</script>

<style scoped>
.fade-in {
  opacity: 0;
  transition: 0.8s all ease-out;
  transform: scale(0.8);
  display: inline-block;
}
</style>