<template>
  <v-container id="tarifs" class="anchor_tags">
    <h2
      class="text-h3 primary--text text-xs-center mt-5 fade-in translate-left"
    >
      Tarifs
    </h2>

    <TarifsImmobilier id="tarifs_immobilier" class="pt-8 mb-2" />
    <TarifsEvenements id="tarifs_evenements" class="anchor_tags pt-8 mb-2" />
    <TarifsAutre id="tarifs_autre" class="anchor_tags pt-8" />

    <div class="text--secondary text-subtitle-2">
      * TVA non applicable selon l’article 293 B du Code Général des Impôts
    </div>
  </v-container>
</template>

<script>
import TarifsImmobilier from "@/components/drone/tarifs/TarifsImmobilier.vue";
import TarifsEvenements from "@/components/drone/tarifs/TarifsEvenements.vue";
import TarifsAutre from "@/components/drone/tarifs/TarifsAutre.vue";

export default {
  name: "Tarifs",
  components: {
    TarifsImmobilier,
    TarifsEvenements,
    TarifsAutre,
  },
};
</script>

<style scoped>
</style>