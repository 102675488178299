<template>
  <div>
    <v-row style="justify-content: center !important;">
      <v-col cols="12" md="6" lg="4" xl="4" v-for="item in list" :key="item.title">
        <v-card>
          <v-img
            :src="item.src"
            :lazy-src="item.lazy"
            contains
          ></v-img>

          <v-card-title> {{ item.title }}</v-card-title>

          <v-card-subtitle>{{ item.subtitle }}</v-card-subtitle>

          <v-card-actions>
            <v-btn
              color="primary"
              text
              target="_blank"
              :href="item.href"
            >
              Allez sur le site
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
                color="secondary" icon @click="item.show = !item.show">
              <v-icon>{{
                item.show ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="item.show">
              <v-divider></v-divider>

              <v-card-text class="text-justify">
                <span v-html="item.text"></span>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>

    <!--<v-sheet>
      <v-slide-group v-model="model" show-arrows mandatory>
        <v-slide-item v-for="n in 15" :key="n">
          <v-card
            color="grey lighten-1"
            class="ma-4"
            height="200"
            width="100"
            @click="dialog = true"
          >
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>-->

    <!--<v-dialog v-model="dialog" width="100%" scrollable>
      <v-card>
        <v-card-title>
          <span class="text-h5">Application réalisée</span>
        </v-card-title>
        <v-card-text>
            LONG TEXTE
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Fermer </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->
  </div>
</template>


<script>
export default {
  name: "ListeRealisations",

  data: () => ({
    model: null,
    dialog: false,
    show: false,
    list:[
        {
            src:require('@/assets/developpement/realisations/simulateur_credit_conso.jpg'),
            lazy:require('@/assets/developpement/realisations/simulateur_credit_conso-lazy.jpg'),
            title:"Simulateur",
            subtitle:"crédit à la consommation",
            href:"https://atlantique-vendee.credit-agricole.fr/simulateur-credit-conso/",
            show:false,
            text:"Réalisation d'une API pour calculer un crédit à la consommation en fonction de plusieurs paramètres d'entrées : un capital, une durée de remboursement, une durée de différé (totale ou partielle), un taux nominal. L'algorithme prend en compte plusieurs types de calcul en fonction du type de prêt. Les données de sorties sont un tableau d'amortissement, les frais de dossier calculés, le calcul de l'assurance, le TAEA, le TAEG, le remboursement mensuel durant le différé, le remboursement mensuel avec ou sans assurance, les intêrets calculés ..."
        },
        {
            src:require('@/assets/developpement/realisations/reco_parrainage.jpg'),
            lazy:require('@/assets/developpement/realisations/reco_parrainage-lazy.jpg'),
            title:"Parrainage",
            subtitle:"formulaire & RGPD",
            href:"https://www.ca-atlantique-vendee.net/recommandation-parrainage/",
            show:false,
            text:"Réalisation d'une application de recommandation de prospects pour le Crédit Agricole Atlantique Vendée en prenant en compte la RGPD. Objectif : Un client peut recommander un ami / connaissance / entreprise / association avec un envoi unique de mail par le serveur. Aucune information du prospect n'est connu ni sauvegardé par le Crédit Agricole. Si le prospect souhaite être contacté, l'application permet de récupérer ses données via un formulaire tout en reliant automatiquement ce prospect à son parrain."
        },
    ]
  }),
  created() {
    this.show = true;
  },
  mounted() {},
  destroyed() {},
  watch: {
    dialog() {
      if (this.dialog) {
        document.querySelector(":root").style.overflow = "hidden";
      } else {
        document.querySelector(":root").style.overflow = null;
      }
    },
  },
  methods: {},
};
</script>