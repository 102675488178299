<template>
  <v-container id="services" class="anchor_tags">
    <h2
      class="text-h3 primary--text text-xs-center mt-5 fade-in translate-left"
    >
      Services
    </h2>
    <v-row class="services-card mt-10">
      <v-col
        v-for="(service, index) in services"
        :key="index"
        :class="service.class"
        cols="12"
      >
        <v-card elevation="0" class="text-center mb-3 fade-in scale-enlarge">
          <v-btn
            elevation="2"
            fab
            icon
            color="accent darken-1"
            :to="service.href"
            ><v-icon>{{ service.icon }}</v-icon></v-btn
          >
          <v-card-title
            class="mt-5 py-0 secondary--text d-block text-uppercase"
            style="word-break: break-word"
          >
            {{ service.title }}
          </v-card-title>
          <v-card-text class="text-justify px-5 pt-3 pb-0 black--text">
            <span v-html="service.text"></span>
          </v-card-text>
          <v-btn text color="primary" class="ma-2" dark :to="service.href">
            En savoir plus
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Services",
  components: {},

  data: () => ({
    services: [
      {
        href: "/drone/prestation-immobilier",
        class: "col-md-6 col-lg-4",
        icon: "mdi-home",
        title: "Mettre en valeur un bien immobilier",
        text:
          "Besoin de vendre une maison ou un appartement ? Besoin de présenter des chambres d'hôtes ou une grande propriété à louer ? Grâce à une vidéo aérienne ou prise au sol, vous vous assurez une visibilité maximale du bien.",
      },
      {
        href: "/drone/prestation-mariage",
        class: "col-md-6 col-lg-4",
        icon: "mdi-ring",
        title: "Immortaliser un mariage",
        text:
          "De la sortie de la célébration jusqu'au survol du lieu de réception en passant par l'arrivée des mariés, le drone offre des images spectaculaires. Après des mois de préparation pour un jour unique dans un lieu magique gardez-en un souvenir aérien !",
      },
      {
        href: "/drone/prestation-evenement",
        class: "col-md-6 col-lg-4",
        icon: "mdi-message-video",
        title: "Communiquer sur un évènement",
        text:
          "Vous organisez un évènement public ou privé et souhaitez des prises de vues aériennes pour communiquer ou en garder un souvenir inédit et original ? N'hésitez pas à me contacter pour m'en parler.",
      },
      {
        href: "/drone/prestation-tourisme",
        class: "col-md-6 col-lg-4",
        icon: "mdi-earth",
        title: "Tourisme",
        text:
          "Vous souhaitez communiquer sur un site touristique et souhaitez bénéficier de prises de vues aériennes à couper le souffle pour promouvoir efficacement sur les réseaux sociaux, brochure ou covering ?",
      },
      {
        href: "/drone/prestation-entreprise",
        class: "col-md-6 col-lg-4",
        icon: "mdi-play-network",
        title: "Présenter une entreprise",
        text:
          "Vous souhaitez présenter votre entreprise ou une activité qui se développe ? Grâce au drone, Prenez de la hauteur pour avoir une vue globale et marquante de votre projet.",
      },
      {
        href: "/drone/prestation-telepilotage",
        class: "col-md-6 col-lg-4",
        icon: "mdi-quadcopter",
        title: "Télépilotage de drone",
        text:
          "La pratique du drone en France est très réglementée et <span class='noBreakWord'>Dev & Drone</span> veille à respecter toutes les obligations prévues par la loi. Pour toute demande relative à la prestation de télépilotage de drone pour votre projet, n'hésitez pas à me contacter.",
      },
    ],
  }),
  created() {},
  mounted() {},
  destroyed() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style scoped>
.services-card .v-card .v-btn--icon {
  height: 100px;
  width: 100px;
}

.services-card .v-card .v-icon {
  height: 60px;
  width: 60px;
  font-size: 60px;
}

@media screen and (min-width: 960px) {
  .v-dialog__content .v-card {
    padding-top: 64px !important;
  }
}
</style>