<template>
  <v-container id="services" class="anchor_tags">
    <h2
      class="text-h3 primary--text text-xs-center my-5 fade-in translate-left"
    >
      Services
    </h2>
    <div class="fade-in translate-bottom">
      Basé à Nantes, je propose mes services de prestataire principalement en
      <span class="font-weight-medium">régie</span> (avec télétravail possible),
      <span class="font-weight-medium">intégré à vos équipes</span>, pour que
      vous puissiez bénéficier de mon expertise. Je peux aussi venir en
      <span class="font-weight-medium">renfort</span>
      sur une montée en puissance de la charge de travail.
      <br />
      Selon votre projet, une prestation au forfait peut aussi être
      envisageable.
    </div>
    <div
      class="
        text-h6
        secondary--text
        my-5
        font-weight-regular
        fade-in
        translate-bottom
      "
    >
      Pourquoi engager un prestataire plutôt qu'engager un CDD ?
    </div>
    <div class="fade-in translate-bottom">
      <ul>
        <li>
          Vous ne m'engagez que lorsque vous en avez
          <span class="font-weight-medium">besoin</span>.
        </li>
        <li>
          Vous pouvez conclure plusieurs contrats de prestations
          <span class="font-weight-medium">sans limite</span>
        </li>
        <li>
          Vous n'avez
          <span class="font-weight-medium">aucune charge salariale</span> à
          payer
        </li>
        <li>
          Vous n'avez
          <span class="font-weight-medium">aucune fiche de salaire</span> à
          gérer
        </li>
        <li>
          Vous n'avez que des <span class="font-weight-medium">factures</span> à
          régler
        </li>
      </ul>
    </div>
    <v-row class="services-card mt-10">
      <v-col
        v-for="(service, index) in services"
        :key="index"
        :class="service.class"
        cols="12"
      >
        <v-card elevation="0" class="text-center mb-3 fade-in scale-enlarge">
          <v-btn
            elevation="2"
            fab
            icon
            color="accent darken-1"
            :to="(service.href = !null ? service.href : '')"
            ><v-icon>{{ service.icon }}</v-icon></v-btn
          >
          <v-card-title
            class="mt-5 py-0 secondary--text d-block text-uppercase"
            style="word-break: break-word"
          >
            {{ service.title }}
          </v-card-title>
          <v-card-text class="text-justify px-5 pt-3 pb-0 black--text">
            <span v-html="service.text"></span>
          </v-card-text>
          <v-btn
            v-if="service.href"
            text
            color="primary"
            class="ma-2"
            dark
            :to="service.href"
          >
            En savoir plus
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center mt-10">
      <v-btn
        text
        color="primary"
        class="ma-2"
        dark
        href="https://www.malt.fr/profile/floriandetourne"
        target="_blank"
      >
        <v-img
          class="v-icon mr-3"
          height="24px"
          width="24px"
          :src="
            require('../../assets/developpement/partenaires/logo_malt_40.png')
          "
        >
        </v-img>
        Accéder au profil freelance
      </v-btn>

      <v-btn
        text
        color="primary"
        class="ma-2"
        dark
        href="https://dev-et-drone.fr/static/cv_florian_detourne.pdf"
        target="_blank"
      >
      
        Accéder au CV de Florian DÉTOURNÉ
                      <v-icon color="accent darken-1" class="ml-3"
                        >mdi-file-document</v-icon
                      >
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Services",
  components: {},

  data: () => ({
    services: [
      {
        href: null,
        class: "col-md-6 col-lg-5 offset-lg-1",
        icon: "mdi-account-tie-voice",
        title: "Expertise, Conseil & Conception",
        text: "Une application sur mesure nécessite de prendre en compte votre cœur de métier, je peux donc intervenir dès la phase d'<span class='font-weight-medium'>analyse et de conception</span> afin d'<span class='font-weight-medium'>acquérir votre métier</span> (son vocabulaire et son fonctionnement), identifier les contraintes et exigences du projet et définir le périmètre fonctionnel de votre application",
      },
      {
        href: null,
        class: "col-md-6 col-lg-5",
        icon: "mdi-language-php",
        title: "Développeur PHP",
        text: "Développeur PHP de formation, je peux développer des applications avec <span class='font-weight-medium'>PHP 7</span> ou inférieur en me conformant aux règles essentielles de bonnes pratiques PHP en utilisant un gestionnaire de version comme <span class='font-weight-medium'>Github, Bitbucket ou SVN</span>.",
      },
      {
        href: null,
        class: "col-md-6 col-lg-5 offset-lg-1",
        icon: "mdi-database",
        title: "Base de données",
        text: "Je peux intervenir de la conception de la base de données jusqu'à la gestion. Une base de données bien conçue est indispensable pour garantir la <span class='font-weight-medium'>cohérence des informations</span>, éliminer les données redondantes, exécuter efficacement les requêtes et <span class='font-weight-medium'>améliorer les performances</span> de la base de données. J'utilise principalement <span class='font-weight-medium'>MySQL</span> mais je peux utiliser d'autres SGBD comme <span class='font-weight-medium'>Microsoft SQL Server ou Oracle</span>",
      },
      {
        href: null,
        class: "col-md-6 col-lg-5",
        icon: "mdi-language-html5",
        title: "Développeur front",
        text: "La première chose qu'un utilisateur voit est la partie visible et le développement front-end intervient à ce moment. En utilisant le <span class='font-weight-medium'>HTML5, le CSS3 et le Javascript</span> accompagné par des framework comme <span class='font-weight-medium'>VueJs</span> ou des librairies comme <span class='font-weight-medium'>Vuetify, Jquery ou Bootstrap</span>, il est possible de faire des applications ergonomiques et fonctionnelles",
      },
    ],
  }),
  created() {},
  mounted() {},
  destroyed() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style scoped>
.services-card .v-card .v-btn--icon {
  height: 100px;
  width: 100px;
}

.services-card .v-card .v-icon {
  height: 60px;
  width: 60px;
  font-size: 60px;
}

@media screen and (min-width: 960px) {
  .v-dialog__content .v-card {
    padding-top: 64px !important;
  }
}
</style>