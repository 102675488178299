<template>
  <div id="HomeDrone">
    <Carroussel />
    <Realisations />
    <v-parallax
      :height="getWindowInnerHeight"
      v-if="!isMobile"
      :src="require('@/assets/drone/drone-montagne.jpg')"
      :lazy-src="require('@/assets/drone/drone-montagne-lazy.jpg')"
    ></v-parallax>
    <v-img
      v-else
      :src="require('@/assets/drone/drone-montagne.jpg')"
      :lazy-src="require('@/assets/drone/drone-montagne-lazy.jpg')"
    ></v-img>
    <Services />
    <v-parallax
      :height="getWindowInnerHeight"
      v-if="!isMobile"
      :src="require('@/assets/drone/paysage-drone.jpg')"
      :lazy-src="require('@/assets/drone/paysage-drone-lazy.jpg')"
    >
    </v-parallax>
    <v-img
      v-else
      :src="require('@/assets/drone/paysage-drone.jpg')"
      :lazy-src="require('@/assets/drone/paysage-drone-lazy.jpg')"
    >
    </v-img>
    <Tarifs />
  </div>
</template>

<script>
// @ is an alias to /src
import Carroussel from "@/components/drone/Carroussel.vue";
import Realisations from "@/components/drone/Realisations.vue";
import Services from "@/components/drone/Services.vue";
import Tarifs from "@/components/drone/Tarifs.vue";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  metaInfo() {
    return {
      title: " - Vidéos & Photos par Drone",
      meta: [
        {
          name: "description",
          content:
            "Dev & Drone réalise des prises de vues aériennes (photos & vidéos) pour mettre en valeur un bien immobilier ou une zone touristique. Le drone peut aussi immortaliser un évènement comme un mariage ou présenter une entreprise.",
        },
        { property: "og:image", content: "https://dev-et-drone.fr/static/og-image-drone.jpg" },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.dev-et-drone.fr/drone"
        },
      ],
    };
  },
  components: {
    Carroussel,
    Realisations,
    Services,
    Tarifs,
  },
  data: () => ({}),
  mounted() {},
  destroyed() {},
  updated() {},
  methods: {},
  computed: {
    ...mapGetters(["isMobile", "getProperty__vh"]),
    getWindowInnerHeight() {
      return window.innerHeight;
    },
  },
};
</script>

<style scoped>
#HomeDrone {
  overflow: hidden;
}

.v-parallax,
.v-image {
  height: 100vh !important; /*à laisser si calc non supporté*/
  /*height: calc(100 * var(--vh)) !important;*/
}
</style>

<style>
.stopParralax {
  transform: none !important;
  height: 100% !important;
  left: 0 !important;
  top: 0 !important;
}
</style>