<template>
  <v-container id="tarifs" class="anchor_tags">
    <h2
      class="text-h3 primary--text text-xs-center mt-5 fade-in translate-left"
    >
      Tarifs
    </h2>
    <div class="mt-5 fade-in translate-bottom text-justify">
      Votre <span class="font-weight-medium">projet</span> est unique et par
      conséquent, le devis sera personnalisé et fait sur-mesure en vous
      proposant le <span class="font-weight-medium">meilleur tarif</span> qui
      conviendra à votre besoin. N'hésitez pas à me contacter si vous avez des
      questions, je suis à votre écoute.
    </div>
    <div class="mt-6">
      <v-row dense>
        <v-col
          v-for="(card, index) in cards"
          :key="index"
          :class="card.rowClass"
        >
          <v-card class="mx-auto mb-12 fade-in card-tarif" :class="card.class">
            <v-img
              height="250"
              class="white--text align-end"
              :src="card.src"
              :lazy-src="card.lazy"
            >
              <v-card-title>
                <div style="text-shadow: 0 0 1em black; word-break: break-word">
                  <div class="text-subtitle-2">
                    {{ card.from ? "à partir de " : "" }}
                  </div>
                  <span class="text-h2 font-weight-bold">
                    {{ card.price }} €
                  </span>
                   HT* {{ card.periodicity ? "/" + card.periodicity : "" }}
                </div>
              </v-card-title>
            </v-img>

            <v-card-title class="text-h5" style="word-break: break-word">{{
              card.title
            }}</v-card-title>
            <v-card-title
              class="subtitle-1 py-0"
              style="
                margin-top: -20px;
                margin-bottom: -8px;
                word-break: break-word;
              "
              v-if="card.subtitle"
              >{{ card.subtitle }}</v-card-title
            >

            <v-card-text>
              <v-list two-line>
                <div v-for="(item, index) in card.list" :key="index">
                  <v-divider
                    v-if="item.type == 'divider'"
                    :inset="item.inset"
                    :class="item.inset ? '' : 'mb-4'"
                  ></v-divider>
                  <v-subheader
                    v-else-if="item.type == 'subheader'"
                    class="text-subtitle-1"
                  >
                    {{ item.text }}
                  </v-subheader>

                  <v-list-item v-else-if="item.type == 'item'">
                    <v-list-item-icon v-if="item.appendIcon != null">
                      <v-icon :color="item.appendIconColor">
                        {{ item.appendIcon }}
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content class="">
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                      <v-list-item-subtitle v-if="item.subtitle != null"
                        >{{ item.subtitle }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-icon v-if="item.prependIcon != null">
                      <v-icon :color="item.prependIconColor">
                        {{ item.prependIcon }}
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </div>
              </v-list>
              <v-card-actions
                v-if="card.listExpand != null"
                style="position: relative; z-index: 1"
              >
                <v-divider inset class="mr-5"></v-divider>
                <v-btn
                  icon
                  @click="card.showExpand = !card.showExpand"
                  color="accent"
                >
                  <v-icon>{{
                    card.showExpand ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition v-if="card.listExpand != null" class="mt-2">
                <v-list
                  two-line
                  v-show="card.showExpand"
                  class="pa-0"
                  style="position: relative; z-index: 0"
                >
                  <div
                    v-for="(itemExpand, indexExpand) in card.listExpand"
                    :key="index + '-' + indexExpand"
                  >
                    <v-divider
                      v-if="itemExpand.type == 'divider'"
                      :inset="itemExpand.inset"
                    ></v-divider>
                    <v-subheader
                      v-else-if="itemExpand.type == 'subheader'"
                      class="text-subtitle-1 mt-4"
                    >
                      {{ itemExpand.text }}
                    </v-subheader>

                    <v-list-item v-else-if="itemExpand.type == 'item'">
                      <v-list-item-icon v-if="itemExpand.appendIcon != null">
                        <v-icon :color="itemExpand.appendIconColor">
                          {{ itemExpand.appendIcon }}
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content class="">
                        <v-list-item-title>{{
                          itemExpand.title
                        }}</v-list-item-title>
                        <v-list-item-subtitle v-if="itemExpand.subtitle != null"
                          >{{ itemExpand.subtitle }}
                          <v-tooltip bottom v-if="itemExpand.tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                style="margin-top: -3px"
                                >mdi-information-outline</v-icon
                              >
                            </template>
                            <span>{{ itemExpand.tooltip }}</span>
                          </v-tooltip>
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-icon v-if="itemExpand.prependIcon != null">
                        <v-icon :color="itemExpand.prependIconColor">
                          {{ itemExpand.prependIcon }}
                        </v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </div>
                  <v-divider class="mx-4 mt-3"></v-divider>
                </v-list>
              </v-expand-transition>

              <v-divider v-else class="mx-4"></v-divider>
            </v-card-text>

            <v-card-actions>
              <v-btn
                style="position: relative; z-index: 2"
                color="primary"
                x-large
                text
                block
                class="mt-5 mb-2"
                to="/contact?devis"
              >
                DEMANDER UN DEVIS
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div class="text--secondary text-subtitle-2 mt-10">
      * TVA non applicable selon l’article 293 B du Code Général des Impôts
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Tarifs",
  components: {},
  data: () => ({
    cards: [
      {
        title: "Prestation",
        subtitle: "Dans le domaine du développement web",
        from: true,
        price: "350",
        periodicity: "jour",
        src: require("@/assets/developpement/business-2717063_640.jpg"),
        lazy: require("@/assets/developpement/business-2717063_640-lazy.jpg"),
        rowClass:
          "cols-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3",
        class: "translate-bottom",
        list: null,
        showExpand: false,
        listExpand: null,
      },
    ],
  }),
};
</script>

<style scoped>
.card-tarif .v-list .v-subheader {
  height: 30px;
  display: block;
  margin-top: 16px;
  text-align: center;
}

.card-tarif .v-list .v-subheader:first-of-type {
  margin-top: 0px;
}

.card-tarif .v-list--two-line .v-list-item {
  min-height: 56px;
}

.card-tarif .v-list--two-line .v-list-item .v-list-item__icon {
  margin-top: 12px;
  margin-bottom: 20px;
}
.card-tarif .v-list--two-line .v-list-item .v-list-item__content {
  padding: 0;
}

.card-tarif
  .v-list--two-line
  .v-list-item
  .v-list-item__content
  .v-list-item__subtitle,
.card-tarif
  .v-list--two-line
  .v-list-item
  .v-list-item__content
  .v-list-item__title {
  white-space: break-spaces;
}

.card-tarif
  .v-list--two-line
  .v-list-item
  .v-list-item__content
  .v-list-item__subtitle {
  padding-bottom: 10px;
}

.card-tarif .v-card__actions {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: -15px;
  margin-bottom: -15px;
}

.card-tarif .v-card__actions .v-icon {
  height: 80px;
  width: 80px;
}
</style>