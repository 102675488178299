<template>
  <span>
    <v-app-bar app color="primary" dark elevate-on-scroll>
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = true"
      ></v-app-bar-nav-icon>
      <v-img
        alt="Dev & Drone Logo"
        class="shrink mr-2"
        contain
        :src="require('../assets/logos/dev_et_drone_blanc.png')"
        transition="scale-transition"
        height="50"
        width="100"
        v-on:click="gotoUnivers"
        style="cursor: pointer"
      />
      <v-spacer></v-spacer>

      <v-btn text class="px-10 hidden-sm-and-down" to="/presentation"
        >Présentation</v-btn
      >

      <v-menu
        v-for="(elem, index) in menu"
        v-model="elem.model"
        :key="index"
        dark
        open-on-hover
        offset-y
        bottom
        :close-delay="closeDelay"
        transition="slide-y-transition"
        class="hidden-sm-and-down"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            v-on="on"
            class="px-10 dropdown-hover hidden-sm-and-down"
            :to="elem.path"
          >
            {{ elem.title }}
          </v-btn>
        </template>

        <v-list dark class="primary">
          <v-list-item
            v-for="(item, index2) in elem.items"
            :key="index2"
            link
            :to="item.path"
          >
            <v-list-item-icon v-if="!item.sublist">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title v-if="!item.sublist">{{
              item.title
            }}</v-list-item-title>
            <v-menu
              v-if="item.sublist"
              v-model="item.model"
              dark
              open-on-hover
              offset-x
              right
              :close-delay="closeDelay"
              transition="slide-x-transition"
              class="hidden-sm-and-down"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  text
                  v-bind="attrs"
                  v-on="on"
                  class="dropdown-hover v-list-item v-list-item--link px-4"
                  style="margin-left: -16px; margin-right: -16px"
                  :to="item.path"
                >
                  <div class="v-list-item__icon">
                    <v-icon left>
                      {{ item.icon }}
                    </v-icon>
                  </div>
                  <div class="v-list-item__title">{{ item.title }}</div>
                </div>
              </template>

              <v-hover v-model="modelHoverSublist">
                <v-list dark class="primary" style="margin-top: -8px">
                  <v-list-item
                    v-for="(item, index3) in item.sublist"
                    :key="index3"
                    link
                    :to="item.path"
                    @click="clickList"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-hover>
            </v-menu>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-spacer class="hidden-sm-and-down"></v-spacer>

      <v-btn color="primary darken-1" to="/contact">ME CONTACTER</v-btn>
    </v-app-bar>

    <v-navigation-drawer
      app
      temporary
      v-model="drawer"
      class="primary"
      dark
      width="270"
    >
      <div style="height: 153px"></div>
      <v-list nav>
        <v-list-item link class="text--lighten-5" to="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Accueil</v-list-item-title>
        </v-list-item>

        <v-list-item link class="text--lighten-5" to="/presentation">
          <v-list-item-icon>
            <v-icon>mdi-account-details</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Présentation</v-list-item-title>
        </v-list-item>

        <v-list-group
          no-action
          class="text--lighten-5"
          :prepend-icon="elem.icon"
          v-for="elem in menu"
          :key="elem.title"
          active-class="info--text text--lighten-2"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ elem.title }}</v-list-item-title>
          </template>
          <!--<div v-for="item in elem.items" :key="item.title" :class="!item.sublist ? 'v-list-item' : ''">-->
          <v-list-item
            v-for="item in elem.items"
            :key="item.title"
            :to="item.path"
            link
          >
            <v-list-item-title v-text="item.title"></v-list-item-title>

            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>

          <!--<v-list-group v-if="item.sublist" :value="true" sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(itemSublist, index) in item.sublist"
                :key="index"
                link
              >
                <v-list-item-title
                  v-text="itemSublist.title"
                ></v-list-item-title>

                <v-list-item-icon>
                  <v-icon v-text="itemSublist.icon"></v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
          </div>-->
        </v-list-group>
      </v-list>
      <v-divider></v-divider>
      <div class="py-5 px-3">
        <v-btn block color="primary darken-1" to="/contact">ME CONTACTER</v-btn>
      </div>

      <div id="logoNavigationDrawer" class="primary">
        <v-img
          alt="Logo Dev & Drone"
          class=""
          :src="require('../assets/logos/logo_blanc.png')"
          transition="scale-transition"
        />
        <v-divider class=""></v-divider>
      </div>
    </v-navigation-drawer>
  </span>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AppNavigation",
  data() {
    return {
      modelHoverSublist: null,
      drawer: false,
      closeDelay: 0,
      menu: [
        {
          model: null,
          title: "Développement",
          icon: "mdi-code-braces",
          path: "/developpement",
          items: [
            {
              title: "Réalisations",
              icon: "mdi-application",
              path: "/developpement#realisations",
            },
            {
              title: "Services",
              icon: "mdi-format-list-text",
              path: "/developpement#services",
            },
            {
              title: "Tarifs",
              icon: "mdi-currency-eur",
              path: "/developpement#tarifs",
            },
          ],
        },
        {
          model: null,
          title: "Vidéos/Photos",
          icon: "mdi-camera-iris",
          path: "/drone",
          items: [
            {
              title: "Réalisations",
              icon: "mdi-television-play",
              path: "/drone#realisations",
            },
            {
              model: null,
              title: "Services",
              icon: "mdi-format-list-text",
              path: "/drone#services",
              sublist: [
                {
                  title: "Immobilier",
                  icon: "mdi-home",
                  path: "/drone/prestation-immobilier",
                },
                {
                  title: "Mariage",
                  icon: "mdi-ring",
                  path: "/drone/prestation-mariage",
                },
                {
                  title: "Évènement",
                  icon: "mdi-message-video",
                  path: "/drone/prestation-evenement",
                },
                {
                  title: "Tourisme",
                  icon: "mdi-earth",
                  path: "/drone/prestation-tourisme",
                },
                {
                  title: "Entreprise",
                  icon: "mdi-play-network",
                  path: "/drone/prestation-entreprise",
                },
                {
                  title: "Télépilotage",
                  icon: "mdi-quadcopter",
                  path: "/drone/prestation-telepilotage",
                },
              ],
            },
            {
              title: "Tarifs",
              icon: "mdi-currency-eur",
              path: "/drone#tarifs",
            },
          ],
        },
      ],
    };
  },
  watch: {
    modelHoverSublist: function () {
      if (this.modelHoverSublist) {
        this.addForce();
      } else {
        this.removeForce();
      }
    },
  },
  computed: {
    ...mapState(["appTitle"]),
  },
  methods: {
    gotoUnivers: function () {
      this.$router.push("/");
    },
    clickList: function () {
      this.removeForce();
    },
    addForce() {
      let vm = this;
      setTimeout(function () {
        vm.menu[1].model = true;
      }, 1);

      if (document.getElementsByClassName("menuable__content__active")[0])
        document
          .getElementsByClassName("menuable__content__active")[0]
          .setAttribute("id", "forceActive1");

      if (document.querySelector(".dropdown-hover.v-btn[aria-expanded='true']"))
        document
          .querySelector(".dropdown-hover.v-btn[aria-expanded='true']")
          .classList.add("forceActiveBtn");

      if (document.getElementsByClassName("menuable__content__active")[1]) {
        document
          .getElementsByClassName("menuable__content__active")[1]
          .setAttribute("id", "forceActive2");
        document.getElementById("forceActive2").style.zIndex = "10";
      }

      if (
        document.querySelector(
          ".dropdown-hover.v-list-item[aria-expanded='true']"
        )
      )
        document
          .querySelector(".dropdown-hover.v-list-item[aria-expanded='true']")
          .classList.add("forceActiveBtn");
    },
    removeForce() {
      let vm = this;
      setTimeout(function () {
        if (!document.querySelector(".v-list-item:hover")) {
          vm.menu[1].model = false;
        }
        if (document.getElementById("forceActive1"))
          document.getElementById("forceActive1").removeAttribute("id");
        if (document.getElementById("forceActive2")) {
          document.getElementById("forceActive2").style.zIndex = "7";
          document.getElementById("forceActive2").removeAttribute("id");
        }
        if (document.getElementsByClassName("forceActiveBtn")[1])
          document
            .getElementsByClassName("forceActiveBtn")[1]
            .classList.remove("forceActiveBtn");
        if (document.getElementsByClassName("forceActiveBtn")[0])
          document
            .getElementsByClassName("forceActiveBtn")[0]
            .classList.remove("forceActiveBtn");
      }, 100);
    },
  },
};
</script>

<style scoped>
.dropdown-hover[aria-expanded="true"]::before {
  opacity: 0.08;
}

.v-navigation-drawer {
  height: calc(100 * var(--vh)) !important;
}

#logoNavigationDrawer {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

#logoNavigationDrawer .v-image {
  margin: 12px;
  margin-bottom: 20px;
}

#forceActive1,
#forceActive2 {
  display: inline-block !important;
  transition: none !important;
  opacity: 1 !important;
  transform: none !important;
}

.forceActiveBtn::before {
  opacity: 0.08;
}
</style>