<template>
  <v-container class="mt-16">
    <h2
      class="
        text-h4
        primary--text
        mb-10
        mt-5
        font-weight-bold
        text-center
        fade-in
        translate-bottom
      "
    >
      Qui suis-je ?
    </h2>
    <v-row>
      <v-col cols="12" lg="8" offset-lg="2" md="10" offset-md="1">
        <v-card class="mx-auto fade-in translate-bottom" elevation="10">
          <v-col
            cols="12"
            sm="4"
            xl="3"
            style="float: left"
            class="mr-5 mb-2 elevation-3"
            id="card-profil"
          >
            <v-row>
              <v-col cols="5" sm="12" class="pa-0">
                <v-img
                  height=""
                  width="100%"
                  :src="require('../assets/florian_detourne_2.jpg')"
                  :lazy-src="require('../assets/florian_detourne_2-lazy.jpg')"
                >
                </v-img>
              </v-col>
              <v-col
                cols="7"
                sm="12"
                class="text-center flexParentCenterCenter"
              >
                <div>
                  <v-card-title
                    class="pt-0"
                    style="display: block; word-break: initial"
                    >Florian Détourné</v-card-title
                  >
                  <v-card-subtitle class="py-0">
                    Fondateur de
                    <span class="noBreakWord">Dev &amp; Drone</span>
                  </v-card-subtitle>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <div cols="12" sm="8" xl="9" class="text-justify pa-5">
            <div
              class="
                text-h5
                secondary--text
                mb-5
                mt-3
                font-weight-medium
                text-center
              "
            >
              Portrait
            </div>
            <span class="pl-8"
              >Je suis curieux, généreux, passionné, attentionné, altruiste,
              ambitieux, persévérant, quelqu'un sur qui on peut compter. Je
              prends plaisir à apprendre de nouvelles choses ainsi qu'à relever
              des challenges. J'ai toujours voulu rendre service aux autres et
              m'investir pour aller au bout des projets et des missions que l'on
              me confie. Je sais m'adapter aux situations pour atteindre
              l'objectif fixé. Bénévole au sein de l'Ordre de Malte France j'y
              suis investi en tant que secouriste, formateur et responsable de
              l'unité d'intervention en Loire-Atlantique tout en étant
              Sapeurs-Pompiers de Paris réserviste. Je vis à Nantes depuis mon
              plus jeune âge mais je suis toujours prêt à me déplacer, ce ne
              sont pas les kilomètres qui m'arrêtent.</span
            >
          </div>
          <div cols="12" class="text-justify pa-5">
            <div
              class="
                text-h5
                secondary--text
                mb-5
                font-weight-medium
                text-center
              "
            >
              Parcours
            </div>
            <span class="pl-8"
              >Diplômé de l'ENI École Informatique à Saint-Herblain en tant que
              "Concepteur Développeur Informatique" en 2018, j'ai réalisé 4 ans
              d'alternance sur Nantes dans le développement web et applicatif.
              J'ai commencé par 3 ans d'alternance à GRDF dans le Groupe
              Régional d'Expertise et Appui (GREA) puis 1 an au Crédit Agricole
              Atlantique Vendée dans le service Communication Commerciale. La
              rigueur, la réactivité, l'autonomie, la force de proposition et la
              compréhension des demandes clients sont autant de compétences que
              j'ai pu développer au contact de mes collègues et des clients en
              interne. En 2020, j'ai ainsi décidé de me lancer dans le projet
              que je mûrissais depuis des années : créer ma propre
              entreprise.</span
            >
            <div class="mt-5"></div>
            <span class="pl-8"
              >Développeur web de formation, j'ai aussi choisi de devenir
              télépilote professionnel pour pouvoir exercer ma passion du
              montage vidéo et surtout de la prise de vues aériennes en drone.
              L'expérience acquise me permet aujourd'hui d'avoir une approche
              dans différents domaines tel que le développement web,
              l'accompagnement utilisateur mais aussi la communication et la
              création audiovisuelle. Je sais être à l'écoute et être force de
              propositions pour répondre au plus juste à votre problématique et
              si nécessaire travailler en collaboration avec des partenaires
              pour mener à bien votre projet.</span
            >
            <v-row>
              <v-col cols="12" class="mt-8 pb-0 text-center">
                    Pour en savoir plus :
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="12" sm="6" md="4" offset-md="2">
                <v-list-item
                  href="https://www.linkedin.com/in/florian-detourne/"
                  target="_blank"
                >
                  <v-list-item-content class="text-center secondary--text">
                    <v-list-item-title
                      >Profil Linkedin
                      <v-icon color="secondary" class="ml-1"
                        >mdi-linkedin</v-icon
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-list-item
                  href="https://www.malt.fr/profile/floriandetourne"
                  target="_blank"
                >
                  <v-list-item-content class="text-center secondary--text">
                    <v-list-item-title
                      >Freelance sur Malt
                      
                <v-img class="v-icon ml-1"
                  height="24px"
                  width="24px"
                  :src="require('../assets/developpement/partenaires/logo_malt_40.png')"
                >
                </v-img></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <h2
      class="
        text-h4
        primary--text
        mb-5
        mt-15
        font-weight-bold
        text-center
        fade-in
        translate-bottom
      "
    >
      Que fais-je ?
    </h2>
    <v-card
      class="pa-7 mb-5 text-justify fade-in translate-bottom"
      elevation="10"
    >
      <span class="font-weight-bold noBreakWord">Dev &amp; Drone</span> est une
      entreprise fondée en 2020 par Florian Détourné. Spécialisée dans le
      développement web et les prises du vues aériennes par drone, que vous
      soyez un particulier, un auto-entrepreneur ou une entreprise,
      <span class="font-weight-bold noBreakWord">Dev &amp; Drone</span> saura
      vous accompagner dans votre projet que ce soit :

      <div class="text-h5 secondary--text my-5 font-weight-medium text-center">
        <router-link
          to="/developpement"
          class="secondary--text text-decoration-none"
          >Développement</router-link
        >
      </div>

      <v-row class="text-center">
        <v-col cols="12" sm="6" md="4">
          <v-list-item two-line to="/developpement#services">
            <v-list-item-content>
              <v-list-item-title>Consultant informatique</v-list-item-title>
              <v-list-item-subtitle class="retourLigne"
                >Conception, développement,
                <span class="noBreakWord"
                  >accompagnement ...</span
                ></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-list-item two-line to="/developpement#services">
            <v-list-item-content>
              <v-list-item-title>Développeur back-end</v-list-item-title>
              <v-list-item-subtitle class="retourLigne"
                >PHP, SQL,
                <span class="noBreakWord">SGBD ...</span></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6" offset-sm="3" md="4" offset-md="0">
          <v-list-item two-line to="/developpement#services">
            <v-list-item-content>
              <v-list-item-title>Développeur front-end</v-list-item-title>
              <v-list-item-subtitle class="retourLigne"
                >HTML, CSS, JS,
                <span class="noBreakWord">VueJS ...</span></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>

      <div class="text-h5 secondary--text my-5 font-weight-medium text-center">
        <router-link to="/drone" class="secondary--text text-decoration-none"
          >Drone</router-link
        >
      </div>
      <v-row class="text-center">
        <v-col cols="12" sm="6" md="4">
          <v-list-item two-line to="/drone/prestation-evenement">
            <v-list-item-content>
              <v-list-item-title>Évènementiel</v-list-item-title>
              <v-list-item-subtitle class="retourLigne"
                >Mariage, portes ouvertes,
                <span class="noBreakWord"
                  >festival ...</span
                ></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-list-item two-line to="/drone/prestation-immobilier">
            <v-list-item-content>
              <v-list-item-title>Photos et vidéos immobilier</v-list-item-title>
              <v-list-item-subtitle class="retourLigne"
                >Maison, château,
                <span class="noBreakWord"
                  >copropriété ...</span
                ></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6" offset-sm="3" md="4" offset-md="0">
          <v-list-item two-line to="/drone/prestation-entreprise">
            <v-list-item-content>
              <v-list-item-title>Présentation d'entreprise</v-list-item-title>
              <v-list-item-subtitle class="retourLigne"
                >promotion,
                <span class="noBreakWord"
                  >communication ...</span
                ></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card>
    <h2
      class="
        text-h4
        primary--text
        mb-5
        mt-15
        font-weight-bold
        text-center
        fade-in
        translate-bottom
      "
    >
      Où vais-je ?
    </h2>

    <v-row>
      <v-col cols="12" lg="8" offset-lg="2" md="10" offset-md="1">
        <v-card
          class="pa-7 mb-5 text-justify fade-in translate-bottom"
          elevation="10"
        >
          Le siège social de
          <span class="font-weight-bold noBreakWord">Dev &amp; Drone</span> est
          basé sur Nantes et le télétravail peut être une solution efficace et
          moins coûteuse. Cependant je suis mobile et je me déplace
          principalement en
          <span class="font-weight-medium">Pays de la Loire</span> et en
          <span class="font-weight-medium">Bretagne</span>. En fonction de vos
          besoins, je peux aussi me déplacer dans
          <span class="font-weight-medium">toute la France</span>.

          <div id="map" class="mt-5"></div>
          <span v-html="content"></span>
        </v-card>
      </v-col>
    </v-row>
    <div
      class="
        text-h4
        primary--text
        mb-5
        mt-15
        font-weight-bold
        text-center
        fade-in
        translate-bottom
      "
    >
      Comment me contacter ?
    </div>
    <v-card
      class="pa-7 mb-5 text-justify fade-in translate-bottom"
      elevation="10"
    >
      <div class="text-center">
        Si vous souhaitez plus d'informations ou si vous avez des questions,
        cliquez sur ce bouton tout simplement :
      </div>
      <div class="text-center mt-5">
        <v-btn color="primary" to="/contact" x-large> ME CONTACTER </v-btn>
      </div>
      <span v-html="content"></span>
    </v-card>
  </v-container>
</template>


<script>
export default {
  name: "Presentation",
  metaInfo() {
    return {
      title: " - Présentation",
      link: [
        {
          rel: "canonical",
          href: "https://www.dev-et-drone.fr/presentation",
        },
      ],
    };
  },

  data: () => ({
    googleMap: null,
    content: "",
    googleMapsApiScript: "https://maps.googleapis.com/maps/api/js?key=",
    googleMapsApiKey: "AIzaSyB79a2CjqF8IQarvM9c8R9YL9YI8XIVdmk",
    KML_location:
      "https://dev-et-drone.fr/KML/Dev_&_Drone_location.kml" + "?" + Date.now(),
  }),

  mounted() {
    if (!document.head.innerHTML.includes(this.googleMapsApiScript)) {
      let googleApiMap = document.createElement("script");
      googleApiMap.async = true;
      googleApiMap.defer = true;

      let vm = this;

      googleApiMap.onload = function () {
        vm.initMap();
      };

      googleApiMap.setAttribute(
        "src",
        this.googleMapsApiScript + this.googleMapsApiKey + "&libraries=places" //à laisser pour faire fonctionner la page Contact
      );

      document.head.appendChild(googleApiMap);
    } else {
      this.initMap();
    }
  },
  methods: {
    initMap() {
      /*let location = "France";
      let centerResults;
      let geocoder = new window.google.maps.Geocoder().geocode(
        { address: location },
        function (results, status) {
          if (status == window.google.maps.GeocoderStatus.OK) {
            centerResults = results[0].geometry.center;
          } else {
            alert("Could not find location: " + location);
          }
        }
      );*/

      let googleMapInit = new window.google.maps.Map(
        document.getElementById("map"),
        {
          center: { lat: 46.486299, lng: 2.3482296 },
          zoom: window.innerWidth < 430 ? 4.5 : 5,
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          zoomControl: false,
          gestureHandling: "none",
        }
      );

      let kmlLayer = new window.google.maps.KmlLayer(this.KML_location, {
        suppressInfoWindows: true,
        preserveViewport: true,
        map: googleMapInit,
      });

      /*let vm = this;
      kmlLayer.addListener("click", function (event) {
        var content = event.featureData.infoWindowHtml;
        vm.content = content;
      });*/

      this.googleMap = googleMapInit;
    },
  },
};
</script>


<style scoped>
.noBreakWord {
  word-break: keep-all;
  display: inline-block;
}

.retourLigne {
  white-space: normal;
}

#map {
  height: 350px;
}

@media screen and (max-width: 430px) {
  #map {
    height: 250px;
  }
}
</style>

<style>
#map .gmnoprint {
  display: none;
}

@media screen and (max-width: 600px) {
  #card-profil {
    box-shadow: none !important;
    margin-bottom: 30px !important;
  }
}
</style>