<template>
  <div>
    <div class="fade-in scale-enlarge">
      <h3 class="secondary--text text-h4 text-center">
        IMMOBILIER
      </h3>
      <h4
        class="secondary--text text-subtitle-1 text-center mb-5 font-weight-medium"
      >
        Appartement • Maison • Grande Propriété • Construction
      </h4>
    </div>
    <v-row dense>
      <v-col v-for="(card, index) in cards" :key="index" :class="card.rowClass">
        <v-card class="mx-auto mb-12 fade-in card-tarif" :class="card.class">
          <v-img
            height="250"
            class="white--text align-end"
            :src="card.src"
            :lazy-src="card.lazy"
          >
            <v-card-title>
              <div v-if="card.price" style="text-shadow: 0 0 1em black">
                <span class="text-h2 font-weight-bold">
                  {{ card.price }} €
                </span>
                HT*
              </div>
              <div v-else style="text-shadow: 0 0 1em black">
                <span class="text-h4 font-weight-bold"></span>
              </div>
            </v-card-title>
          </v-img>

          <v-card-title class="text-h5" style="word-break: break-word">{{
            card.title
          }}</v-card-title>
          <v-card-title
            class="subtitle-1 py-0"
            style="margin-top: -20px; margin-bottom: -8px"
            v-if="card.subtitle"
            >{{ card.subtitle }}</v-card-title
          >

          <v-card-text>
            <v-list two-line>
              <div v-for="(item, index) in card.list" :key="index">
                <v-divider
                  v-if="item.type == 'divider'"
                  :inset="item.inset"
                  :class="item.inset ? '' : 'mb-4'"
                ></v-divider>
                <v-subheader
                  v-else-if="item.type == 'subheader'"
                  class="text-subtitle-1"
                >
                  {{ item.text }}
                </v-subheader>

                <v-list-item v-else-if="item.type == 'item'">
                  <v-list-item-icon v-if="item.appendIcon != null">
                    <v-icon :color="item.appendIconColor">
                      {{ item.appendIcon }}
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content class="">
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    <v-list-item-subtitle v-if="item.subtitle != null">{{
                      item.subtitle
                    }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-icon v-if="item.prependIcon != null">
                    <v-icon :color="item.prependIconColor">
                      {{ item.prependIcon }}
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </div>
            </v-list>
            <v-card-actions
              v-if="card.listExpand != null"
              style="position: relative; z-index: 1"
            >
              <v-divider inset class="mr-5"></v-divider>
              <v-btn
                icon
                @click="card.showExpand = !card.showExpand"
                color="accent"
              >
                <v-icon>{{
                  card.showExpand ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition v-if="card.listExpand != null" class="mt-2">
              <v-list
                two-line
                v-show="card.showExpand"
                class="pa-0"
                style="position: relative; z-index: 0"
              >
                <div
                  v-for="(itemExpand, indexExpand) in card.listExpand"
                  :key="index + '-' + indexExpand"
                >
                  <v-divider
                    v-if="itemExpand.type == 'divider'"
                    :inset="itemExpand.inset"
                  ></v-divider>
                  <v-subheader
                    v-else-if="itemExpand.type == 'subheader'"
                    class="text-subtitle-1 mt-4"
                  >
                    {{ itemExpand.text }}
                  </v-subheader>

                  <v-list-item v-else-if="itemExpand.type == 'item'">
                    <v-list-item-icon v-if="itemExpand.appendIcon != null">
                      <v-icon :color="itemExpand.appendIconColor">
                        {{ itemExpand.appendIcon }}
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content class="">
                      <v-list-item-title>{{
                        itemExpand.title
                      }}</v-list-item-title>
                      <v-list-item-subtitle v-if="itemExpand.subtitle != null"
                        >{{ itemExpand.subtitle }}
                        <v-tooltip bottom v-if="itemExpand.tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              small
                              style="margin-top: -3px"
                              >mdi-information-outline</v-icon
                            >
                          </template>
                          <span>{{ itemExpand.tooltip }}</span>
                        </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-icon v-if="itemExpand.prependIcon != null">
                      <v-icon :color="itemExpand.prependIconColor">
                        {{ itemExpand.prependIcon }}
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </div>
                <v-divider class="mx-4 mt-3"></v-divider>
              </v-list>
            </v-expand-transition>

            <v-divider v-else class="mx-4"></v-divider>
          </v-card-text>

          <v-card-actions>
            <v-btn
              style="position: relative; z-index: 2"
              color="primary"
              x-large
              text
              block
              class="mt-5 mb-2"
              to="/contact?devis"
            >
              DEMANDER UN DEVIS
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "TarifsImmobilier",

  data: () => ({
    cards: [
      {
        title: "Photos aériennes",
        subtitle: "ce qui est inclus :",
        price: 250,
        src: require("@/assets/immobilier/exterieur.jpg"),
        lazy: require("@/assets/immobilier/exterieur-lazy.jpg"),
        rowClass: "cols-12 col-sm-8 offset-sm-2 col-md-6 offset-md-0 col-lg-4",
        class: "translate-left",
        list: [
          { type: "subheader", text: "SUR PLACE" },
          {
            type: "item",
            appendIcon: "mdi-progress-clock",
            appendIconColor: "secondary",
            title: "~ 1h",
            subtitle: "présence sur les lieux",
            prependIcon: null,
            prependIconColor: null,
          },
          { type: "divider", inset: false },
          { type: "subheader", text: "LIVRABLE" },
          {
            type: "item",
            appendIcon: "mdi-calendar-clock",
            appendIconColor: "secondary",
            title: "Sous 48h",
            subtitle: "Envoi des photos",
            prependIcon: null,
            prependIconColor: null,
          },
          {
            type: "item",
            appendIcon: "mdi-image-multiple",
            appendIconColor: "secondary",
            title: "10 à 15 photos",
            subtitle: "nombres de photos",
            prependIcon: null,
            prependIconColor: null,
          },
          {
            type: "item",
            appendIcon: "mdi-palette",
            appendIconColor: "secondary",
            title: "Retouche colorimétrique",
            subtitle: "Traitement photo",
            prependIcon: null,
            prependIconColor: null,
          },
        ],
        showExpand: false,
        listExpand: [
          { type: "subheader", text: "SPÉCIFICITÉ DRONE" },
          {
            type: "item",
            appendIcon: "mdi-file-document-multiple-outline",
            appendIconColor: "secondary",
            title: "Assurances & Démarches",
            subtitle: "Autorisations de vol",
            tooltip: "non inclus : zone dangereuse, interdite ou militaire.",
            prependIcon: null,
            prependIconColor: null,
          },
          {
            type: "item",
            appendIcon: "mdi-ruler",
            appendIconColor: "secondary",
            title: "120m de hauteur maximum",
            subtitle: "certaines zones peuvent être limitées",
            prependIcon: null,
            prependIconColor: null,
          },
          {
            type: "item",
            appendIcon: "mdi-certificate-outline",
            appendIconColor: "secondary",
            title: "Pilote aggréé DGAC",
            subtitle: "formation théorique et pratique à jour",
            prependIcon: null,
            prependIconColor: null,
          },
        ],
      },
      {
        title: "Vidéos aériennes",
        subtitle: "ce qui est inclus :",
        price: 600,
        src: require("@/assets/landscapes/landscape_10.jpg"),
        lazy: require("@/assets/landscapes/landscape_10-lazy.jpg"),
        rowClass: "cols-12 col-sm-8 offset-sm-2 col-md-6 offset-md-0 col-lg-4",
        class: "translate-bottom",
        list: [
          { type: "subheader", text: "SUR PLACE" },
          {
            type: "item",
            appendIcon: "mdi-progress-clock",
            appendIconColor: "secondary",
            title: "~ 2h",
            subtitle: "présence sur les lieux",
            prependIcon: null,
            prependIconColor: null,
          },
          { type: "divider", inset: false },
          { type: "subheader", text: "LIVRABLE" },
          {
            type: "item",
            appendIcon: "mdi-calendar-clock",
            appendIconColor: "secondary",
            title: "3 jours ouvrés",
            subtitle: "Attente du livrable",
            prependIcon: null,
            prependIconColor: null,
          },
          {
            type: "item",
            appendIcon: "mdi-clock-end",
            appendIconColor: "secondary",
            title: "1 à 3 minutes",
            subtitle: "durée de la vidéo",
            prependIcon: null,
            prependIconColor: null,
          },
          {
            type: "item",
            appendIcon: "mdi-video-high-definition",
            appendIconColor: "secondary",
            title: "Full HD : 1920x1080",
            subtitle: "Qualité vidéo",
            prependIcon: null,
            prependIconColor: null,
          },
        ],
        showExpand: false,
        listExpand: [
          {
            type: "item",
            appendIcon: "mdi-image-plus",
            appendIconColor: "secondary",
            title: "Intégration du logo",
            subtitle: null,
            prependIcon: null,
            prependIconColor: null,
          },
          {
            type: "item",
            appendIcon: "mdi-music",
            appendIconColor: "secondary",
            title: "Musique libre de droit",
            subtitle: "frais supplémentaires si licence payante",
            prependIcon: null,
            prependIconColor: null,
          },
          { type: "divider" },
          { type: "subheader", text: "SPÉCIFICITÉ DRONE" },
          {
            type: "item",
            appendIcon: "mdi-file-document-multiple-outline",
            appendIconColor: "secondary",
            title: "Assurances & Démarches",
            subtitle: "Autorisations de vol",
            tooltip: "non inclus : zone dangereuse, interdite ou militaire.",
            prependIcon: null,
            prependIconColor: null,
          },
          {
            type: "item",
            appendIcon: "mdi-ruler",
            appendIconColor: "secondary",
            title: "120m de hauteur maximum",
            subtitle: "certaines zones peuvent être limitées",
            prependIcon: null,
            prependIconColor: null,
          },
          {
            type: "item",
            appendIcon: "mdi-certificate-outline",
            appendIconColor: "secondary",
            title: "Pilote aggréé DGAC",
            subtitle: "formation théorique et pratique à jour",
            prependIcon: null,
            prependIconColor: null,
          },
        ],
      },
      {
        title: "Vidéos en intérieur",
        subtitle: "ce qui est inclus :",
        price: 300,
        src: require("@/assets/immobilier/interieur.jpg"),
        lazy: require("@/assets/immobilier/interieur-lazy.jpg"),
        rowClass: "cols-12 col-sm-8 offset-sm-2 col-md-6 offset-md-0 col-lg-4",
        class: "translate-right",
        list: [
          { type: "subheader", text: "SUR PLACE" },
          {
            type: "item",
            appendIcon: "mdi-ruler-square",
            appendIconColor: "secondary",
            title: "Surface de 100m² (3 à 5 pièces)",
            subtitle: "50€ par pièce supplémentaire",
            prependIcon: null,
            prependIconColor: null,
          },
          {
            type: "item",
            appendIcon: "mdi-progress-clock",
            appendIconColor: "secondary",
            title: "~ 1h30",
            subtitle: "présence sur les lieux",
            prependIcon: null,
            prependIconColor: null,
          },
          { type: "divider", inset: false },
          { type: "subheader", text: "LIVRABLE" },
          {
            type: "item",
            appendIcon: "mdi-calendar-clock",
            appendIconColor: "secondary",
            title: "3 jours ouvrés",
            subtitle: "Attente du livrable",
            prependIcon: null,
            prependIconColor: null,
          },
          {
            type: "item",
            appendIcon: "mdi-clock-end",
            appendIconColor: "secondary",
            title: "1 à 3 minutes",
            subtitle: "durée de la vidéo",
            prependIcon: null,
            prependIconColor: null,
          },
        ],
        showExpand: false,
        listExpand: [
          {
            type: "item",
            appendIcon: "mdi-video-high-definition",
            appendIconColor: "secondary",
            title: "Full HD : 1920x1080",
            subtitle: "Qualité vidéo",
            prependIcon: null,
            prependIconColor: null,
          },
          {
            type: "item",
            appendIcon: "mdi-image-plus",
            appendIconColor: "secondary",
            title: "Intégration du logo",
            subtitle: null,
            prependIcon: null,
            prependIconColor: null,
          },
          {
            type: "item",
            appendIcon: "mdi-music",
            appendIconColor: "secondary",
            title: "Musique libre de droit",
            subtitle: "frais supplémentaires si licence payante",
            prependIcon: null,
            prependIconColor: null,
          },
        ],
      },
      {
        title: "Pack aérien & intérieur",
        subtitle: "ce qui est inclus :",
        price: 800,
        src: require("@/assets/immobilier/kerlouan.jpg"),
        lazy: require("@/assets/immobilier/kerlouan-lazy.jpg"),
        rowClass:
          "cols-12 col-sm-8 offset-sm-2 col-md-6 offset-md-0 col-lg-6 offset-lg-3",
        class: "translate-bottom",
        list: [
          { type: "subheader", text: "SUR PLACE" },
          {
            type: "item",
            appendIcon: "mdi-ruler-square",
            appendIconColor: "secondary",
            title: "Surface de 100m² (3 à 5 pièces)",
            subtitle: "50€ par pièce supplémentaire",
            prependIcon: null,
            prependIconColor: null,
          },
          {
            type: "item",
            appendIcon: "mdi-progress-clock",
            appendIconColor: "secondary",
            title: "~ 4h",
            subtitle: "présence sur les lieux",
            prependIcon: null,
            prependIconColor: null,
          },
          { type: "divider", inset: false },
          { type: "subheader", text: "LIVRABLE" },
          {
            type: "item",
            appendIcon: "mdi-calendar-clock",
            appendIconColor: "secondary",
            title: "4 jours ouvrés",
            subtitle: "Attente du livrable",
            prependIcon: null,
            prependIconColor: null,
          },
          {
            type: "item",
            appendIcon: "mdi-clock-end",
            appendIconColor: "secondary",
            title: "1 à 3 minutes",
            subtitle: "durée de la vidéo",
            prependIcon: null,
            prependIconColor: null,
          },
        ],
        showExpand: false,
        listExpand: [
          {
            type: "item",
            appendIcon: "mdi-video-high-definition",
            appendIconColor: "secondary",
            title: "Full HD : 1920x1080",
            subtitle: "Qualité vidéo",
            prependIcon: null,
            prependIconColor: null,
          },
          {
            type: "item",
            appendIcon: "mdi-image-plus",
            appendIconColor: "secondary",
            title: "Intégration du logo",
            subtitle: null,
            prependIcon: null,
            prependIconColor: null,
          },
          {
            type: "item",
            appendIcon: "mdi-music",
            appendIconColor: "secondary",
            title: "Musique libre de droit",
            subtitle: "frais supplémentaires si licence payante",
            prependIcon: null,
            prependIconColor: null,
          },
          { type: "divider" },
          { type: "subheader", text: "SPÉCIFICITÉ DRONE" },
          {
            type: "item",
            appendIcon: "mdi-file-document-multiple-outline",
            appendIconColor: "secondary",
            title: "Assurances & Démarches",
            subtitle: "Autorisations de vol",
            tooltip: "non inclus : zone dangereuse, interdite ou militaire.",
            prependIcon: null,
            prependIconColor: null,
          },
          {
            type: "item",
            appendIcon: "mdi-ruler",
            appendIconColor: "secondary",
            title: "120m de hauteur maximum",
            subtitle: "certaines zones peuvent être limitées",
            prependIcon: null,
            prependIconColor: null,
          },
          {
            type: "item",
            appendIcon: "mdi-certificate-outline",
            appendIconColor: "secondary",
            title: "Pilote aggréé DGAC",
            subtitle: "formation théorique et pratique à jour",
            prependIcon: null,
            prependIconColor: null,
          },
        ],
      },
    ],
  }),

  methods: {},
};
</script>

<style scoped>
.card-tarif .v-list .v-subheader {
  height: 30px;
  display: block;
  margin-top: 16px;
  text-align: center;
}

.card-tarif .v-list .v-subheader:first-of-type {
  margin-top: 0px;
}

.card-tarif .v-list--two-line .v-list-item {
  min-height: 56px;
}

.card-tarif .v-list--two-line .v-list-item .v-list-item__icon {
  margin-top: 12px;
  margin-bottom: 20px;
}
.card-tarif .v-list--two-line .v-list-item .v-list-item__content {
  padding: 0;
}

.card-tarif
  .v-list--two-line
  .v-list-item
  .v-list-item__content
  .v-list-item__subtitle,
.card-tarif
  .v-list--two-line
  .v-list-item
  .v-list-item__content
  .v-list-item__title {
  white-space: break-spaces;
}

.card-tarif
  .v-list--two-line
  .v-list-item
  .v-list-item__content
  .v-list-item__subtitle {
  margin-bottom: 10px;
}

.card-tarif .v-card__actions {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: -15px;
  margin-bottom: -15px;
}

.card-tarif .v-card__actions .v-icon {
  height: 80px;
  width: 80px;
}
</style>