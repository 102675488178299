<template>
  <v-container class="mt-16">
    <div class="text-h3 primary--text text-center my-5">Mentions légales</div>
    <div class="text-justify">
      <p>
       dev-et-drone.fr est un site édité par la
        <span class="noBreakWord">SASU Dev & Drone</span>, au capital de
        <span class="noBreakWord">2 000,00 euros</span>, immatriculée au
        Registre du commerce et des sociétés de NANTES sous le numéro
        <span class="noBreakWord">888 496 981</span> et ayant son siège social
        situé 5 boulevard Jean XXIII - 44100 NANTES (SIRET
        <span class="noBreakWord">n° 888 496 981 00018</span>)
      </p>

      <p>
        Directeur de la publication&nbsp;: Monsieur Florian DÉTOURNÉ –
        <router-link to="/contact">contact</router-link>
      </p>

      <p>
        Le site Internet
        <router-link to="/">https://dev-et-drone.fr</router-link> (le Site) est la propriété
        exclusive de la <span class="noBreakWord">SASU Dev & Drone</span>.
      </p>

      <div class="secondary--text text-h5">Hébergement</div>

      <p>
        Le site Internet est hébergé par la société
        <a href="https://www.ionos.fr/" target="_blank">IONOS SARL</a>, 7, place
        de la Gare - 57200 SARREGUEMINES, SIREN
        <span class="noBreakWord">n° 431 303 775 00016</span>
      </p>

      <div class="secondary--text text-h5">Propriété intellectuelle</div>

      <p>
        De manière générale, les données, les programmes, les textes, les informations, les logos, les identités
        visuelles, les images animées ou non et leurs mises en forme, les
        vidéos, apparaissant sur le Site sont la propriété de la SASU 
        <span class="noBreakWord">Dev & Drone</span> et sont protégées à ce
        titre par les dispositions du Code de la propriété intellectuelle.
      </p>

      <p>
        Tout internaute s’engage à ne pas les utiliser que dans le strict cadre
        offert par le Site.
      </p>

      <div class="secondary--text text-h5">Responsabilité</div>

      <p>
        La SASU <span class="noBreakWord">Dev & Drone </span> décline toute
        responsabilité quant aux éventuels dysfonctionnements pouvant survenir
        sur le Site et entraîner une perte de données ou une indisponibilité de
        l’accès aux informations produites sur celui-ci. La SASU
        <span class="noBreakWord">Dev & Drone</span> ne peut garantir
        l’exhaustivité et la véracité des informations présentes sur le Site
        ainsi que l’absence de modification par un tiers (intrusion, virus).
        L’internaute est seul responsable de l’utilisation qu’il fait du contenu
        du Site.
      </p>

      <p>
        Les éléments présentés sur le Site sont susceptibles de modification
        sans préavis et sont mis à la disposition des internautes, sans aucune
        garantie d’aucune sorte, expresse ou tacite.
      </p>

      <p>
        La présence de liens hypertextes présents sur le Site ne crée pas une
        solidarité de responsabilité entre la SASU
        <span class="noBreakWord">Dev & Drone</span> et les propriétaires des
        autres sites, quant au contenu des sites sur lesquels est redirigé
        l’internaute. Seule la responsabilité desdits sites peut être engagée.
      </p>
    </div>
  </v-container>
</template>

<script>

export default {
  name: "MentionsLegales",
  metaInfo() {
    return {
      title: " - Mentions Légales",
    };
  },
};
</script>