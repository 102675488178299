<template>
  <v-stepper
    v-if="!formSend"
    id="formulaireDevis"
    v-model="etapeFormulaire"
    vertical
  >
    <v-stepper-step
      :editable="etapeFormulaire > 1 && !sendingForm"
      :complete="etapeFormulaire > 1"
      step="1"
    >
      <span class="primary--text font-weight-medium">Vos coordonnées</span>
    </v-stepper-step>

    <v-stepper-content step="1">
      <validation-observer ref="observerCoordonnees" v-slot="{ invalid }">
        <form class="mx-3">
          <v-row>
            <v-col cols="12" lg="6" md="12" sm="6" xs="12" class="pt-2 pb-0">
              <validation-provider
                v-slot="{ errors }"
                name="firstname"
                rules="required|max:255"
              >
                <v-text-field
                  @keydown.enter="focusNext"
                  v-model="formulaire.informations.firstname"
                  :error-messages="errors"
                  label="Prénom *"
                  required
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="6" md="12" sm="6" xs="12" class="pt-2 pb-0">
              <validation-provider
                v-slot="{ errors }"
                name="lastname"
                rules="required|max:255"
              >
                <v-text-field
                  @keydown.enter="focusNext"
                  v-model="formulaire.informations.lastname"
                  :error-messages="errors"
                  label="Nom *"
                  required
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" lg="6" md="12" sm="6" xs="12" class="pt-2 pb-0">
              <validation-provider
                v-slot="{ errors }"
                name="email"
                rules="required|email"
              >
                <v-text-field
                  type="email"
                  @keydown.enter="focusNext"
                  v-model="formulaire.informations.email"
                  :error-messages="errors"
                  label="Adresse e-mail *"
                  required
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="6" md="12" sm="6" xs="12" class="pt-2 pb-0">
              <validation-provider
                v-slot="{ failedRules }"
                name="phoneNumber"
                :rules="{
                  regex: '^((\\+|00)33|0)\\s*[1-9](?:[\\s.-]*\\d{2}){4}$',
                }"
              >
                <v-text-field
                  type="number"
                  @keydown.enter="focusNext"
                  v-model="formulaire.informations.phoneNumber"
                  :error-messages="
                    failedRules.regex
                      ? 'Le numéro de téléphone doit être au format français'
                      : ''
                  "
                  label="Numéro de téléphone"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" lg="6" md="12" sm="6" xs="12" class="pt-2 pb-0">
              <v-checkbox
                v-model="formulaire.informations.isCompany"
                :true-value="true"
                :false-value="false"
                class=""
                color="secondary"
                label="Je représente une entreprise"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" lg="6" md="12" sm="6" xs="12" class="pt-2 pb-0">
              <validation-provider
                v-slot="{ errors }"
                name="company"
                :rules="{
                  max: 255,
                  required: formulaire.informations.isCompany,
                }"
              >
                <v-text-field
                  @keydown.enter="focusNext"
                  v-show="formulaire.informations.isCompany"
                  v-model="formulaire.informations.company"
                  :error-messages="errors"
                  label="Nom de l'entreprise *"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>

          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="optIn"
          >
            <v-checkbox
              v-model="formulaire.informations.optIn"
              :error-messages="errors"
              :true-value="true"
              :false-value="null"
              label="J'accepte que mes informations personnelles soient traitées dans le cadre de ma demande *"
              type="checkbox"
              required
            ></v-checkbox>
          </validation-provider>

          <div class="my-2">
            <small>* champs obligatoire</small>
          </div>

          <div class="text-center text-sm-left">
            <v-tooltip top :disabled="!invalid">
              <template v-slot:activator="{ on }">
                <span v-on="on" class="d-inline-block mt-5">
                  <v-btn
                    class=""
                    color="secondary"
                    :disabled="invalid"
                    @click="etapeFormulaire++"
                  >
                    Suivant
                  </v-btn>
                </span>
              </template>
              <span>Veuillez remplir tous les champs obligatoires</span>
            </v-tooltip>
          </div>
        </form>
      </validation-observer>
    </v-stepper-content>

    <v-stepper-step
      :editable="etapeFormulaire > 2 && !sendingForm"
      :complete="etapeFormulaire > 2"
      step="2"
    >
      <span class="primary--text font-weight-medium"
        >Votre demande de prestation</span
      >
    </v-stepper-step>

    <v-stepper-content step="2">
      <form class="mx-3">
        <validation-observer ref="observerDemande" v-slot="{ invalid }">
          <v-tabs v-model="tabPrestation" centered show-arrows>
            <v-tab key="PrestationAudiovisuelle"> Audiovisuel </v-tab>
            <v-tab key="PrestationDeveloppement"> Développement </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabPrestation">
            <v-tab-item key="PrestationAudiovisuelle" class="pb-5">
              <v-row class="my-3">
                <v-col cols="12" sm="6" xs="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="subject"
                    :rules="{ required: isAudiovisuel }"
                  >
                    <v-select
                      :error-messages="errors"
                      v-model="formulaire.drone.prestationSelected"
                      :items="prestationsVSelect"
                      filled
                      label="Type de demande *"
                      item-text="name"
                      item-value="value"
                      menu-props="auto"
                      single-line
                      hide-details
                    >
                      <!-- Template for render data when the select is expanded -->
                      <template slot="item" slot-scope="data">
                        <!-- Divider and Header-->
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-icon>
                            <v-icon>mdi-account</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content v-text="data.item" />
                        </template>
                        <!-- Normal item -->
                        <template v-else>
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.name" />
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-select>

                    <small v-if="formulaire.drone.prestationSelected != null">
                      <v-icon class="mr-1">mdi-information-outline</v-icon
                      ><a
                        target="_blank"
                        :href="prestationsRoute[formulaire.drone.prestationSelected]"
                        >Plus d'informations en cliquant ici</a
                      >
                    </small>
                    <small v-else>
                      Veuillez choisir votre demande pour continuer
                    </small>
                  </validation-provider>
                </v-col>
                <v-col sm="6" xs="12">
                  <v-menu
                    ref="menuEventDate"
                    v-model="menuEventDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="eventDateValidation"
                        :rules="{ required: isAudiovisuel }"
                      >
                        <v-text-field
                          :readonly="isMobile"
                          v-show="formulaire.drone.prestationSelected != null"
                          @keydown.enter="focusNext"
                          v-model="eventDateFormatted"
                          :label="
                            isMariage || isEvenement
                              ? 'Date de l\'évènement *'
                              : 'Date souhaitée'
                          "
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          :error-messages="errors"
                          @blur="
                            formulaire.drone.eventDate = parseDate(
                              eventDateFormatted
                            )
                          "
                          v-on="on"
                          class="mt-3"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      :min="new Date().toISOString().substr(0, 10)"
                      locale="fr"
                      first-day-of-week="1"
                      v-model="formulaire.drone.eventDate"
                      no-title
                      @input="menuEventDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <div v-show="isImmo">
                <v-row class="py-3 flexParentCenterCenter">
                  <v-col
                    cols="12"
                    lg="6"
                    md="12"
                    sm="6"
                    xs="12"
                    class="text-center py-0"
                  >
                    <p>Type de bien à mettre en valeur</p>
                    <v-btn-toggle
                      v-model="formulaire.drone.immobilier.toggleImmobilier"
                      mandatory
                      id="toggleImmobilier"
                    >
                      <v-btn
                        color="secondary"
                        outlined
                        borderless
                        value="maison"
                      >
                        <v-icon color="secondary">mdi-home</v-icon>
                      </v-btn>
                      <v-btn color="secondary" outlined value="immeuble">
                        <v-icon color="secondary">mdi-city</v-icon>
                      </v-btn>
                      <v-btn color="secondary" outlined value="entreprise">
                        <v-icon color="secondary">mdi-factory</v-icon>
                      </v-btn>
                      <v-btn color="secondary" outlined value="autre">
                        <v-icon color="secondary">mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </v-btn-toggle>
                    <p class="mt-3">
                      {{ descriptionToggleImmobilierSelected }}
                    </p>
                  </v-col>
                  <v-col cols="12" lg="6" md="12" sm="6" xs="12" class="py-0">
                    <v-textarea
                      rows="4"
                      v-model="formulaire.drone.immobilier.descriptionBien"
                      label="Description du bien"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </div>

              <div
                v-show="
                  !isMariage && formulaire.drone.prestationSelected != null
                "
              >
                <v-row>
                  <v-col cols="12" class="py-0">
                    <validation-provider
                      v-slot="{ errors }"
                      name="immoAddressValidation"
                      :rules="{ required: isAudiovisuel && !isMariage }"
                    >
                      <v-autocomplete
                        v-model="modelAddressDrone"
                        :items="itemsAddressDrone"
                        :loading="isLoadingAddressDrone"
                        :search-input.sync="formulaire.drone.searchAddressDrone"
                        item-text="Adresse"
                        item-value="AdresseValue"
                        label="Adresse de la prise de vue *"
                        placeholder="Rechercher une adresse"
                        prepend-icon="mdi-map"
                        return-object
                        :hide-no-data="hideNoDataAddressDrone"
                        no-data-text="Aucune adresse trouvée"
                        no-filter
                        :error-messages="errors"
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>

              <div v-show="isMariage">
                <div class="my-2">
                  Choisissez les moments que vous souhaitez immortaliser
                </div>

                <v-row class="mt-5 mb-0 px-6">
                  <v-col
                    cols="12"
                    sm="4"
                    xs="12"
                    class="ma-0 pa-0 d-flex justify-space-around"
                  >
                    <v-checkbox
                      class="ma-0 pa-0"
                      v-model="formulaire.drone.mariage.hasMairie"
                      :true-value="true"
                      :false-value="false"
                      color="secondary"
                      label="Mairie"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    xs="12"
                    class="ma-0 pa-0 d-flex justify-space-around"
                  >
                    <v-checkbox
                      class="ma-0 pa-0"
                      v-model="formulaire.drone.mariage.hasEglise"
                      :true-value="true"
                      :false-value="false"
                      color="secondary"
                      label="Lieu de culte"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    xs="12"
                    class="ma-0 pa-0 d-flex justify-space-around"
                  >
                    <v-checkbox
                      class="ma-0 pa-0"
                      v-model="formulaire.drone.mariage.hasReception"
                      :true-value="true"
                      :false-value="false"
                      color="secondary"
                      label="Réception"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0 mt-0">
                    <validation-provider
                      v-slot="{ errors }"
                      name="weddingMairieAddressValidation"
                      :rules="{
                        required:
                          isMariage && formulaire.drone.mariage.hasMairie,
                      }"
                      v-show="formulaire.drone.mariage.hasMairie"
                    >
                      <v-autocomplete
                        v-model="modelAddressMairie"
                        :items="itemsAddressMairie"
                        :loading="isLoadingAddressMairie"
                        :search-input.sync="
                          formulaire.drone.mariage.searchAddressMairie
                        "
                        item-text="Adresse"
                        item-value="AdresseValue"
                        label="Adresse de la mairie"
                        placeholder="Rechercher une adresse"
                        prepend-icon="mdi-map"
                        append-outer-icon="mdi-city"
                        return-object
                        :hide-no-data="hideNoDataAddressMairie"
                        no-data-text="Aucune adresse trouvée"
                        no-filter
                        :error-messages="errors"
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <validation-provider
                      v-slot="{ errors }"
                      name="weddingEgliseAddressValidation"
                      :rules="{
                        required:
                          isMariage && formulaire.drone.mariage.hasEglise,
                      }"
                      v-show="formulaire.drone.mariage.hasEglise"
                    >
                      <v-autocomplete
                        v-model="modelAddressEglise"
                        :items="itemsAddressEglise"
                        :loading="isLoadingAddressEglise"
                        :search-input.sync="
                          formulaire.drone.mariage.searchAddressEglise
                        "
                        item-text="Adresse"
                        item-value="AddressEglise"
                        label="Adresse du lieu de culte"
                        placeholder="Rechercher une adresse"
                        prepend-icon="mdi-map"
                        append-outer-icon="mdi-church"
                        return-object
                        :hide-no-data="hideNoDataAddressEglise"
                        no-data-text="Aucune adresse trouvée"
                        no-filter
                        :error-messages="errors"
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <validation-provider
                      v-slot="{ errors }"
                      name="weddingReceptionAddressValidation"
                      :rules="{
                        required:
                          isMariage && formulaire.drone.mariage.hasReception,
                      }"
                      v-show="formulaire.drone.mariage.hasReception"
                    >
                      <v-autocomplete
                        v-model="modelAddressReception"
                        :items="itemsAddressReception"
                        :loading="isLoadingAddressReception"
                        :search-input.sync="
                          formulaire.drone.mariage.searchAddressReception
                        "
                        item-text="Adresse"
                        item-value="AddressReception"
                        label="Adresse de la réception"
                        placeholder="Rechercher une adresse"
                        prepend-icon="mdi-map"
                        append-outer-icon="mdi-account-group"
                        return-object
                        :hide-no-data="hideNoDataAddressReception"
                        no-data-text="Aucune adresse trouvée"
                        no-filter
                        :error-messages="errors"
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="mt-3">
                    <div class="flexParentCenterCenter">
                      <label
                        for="hasPostProd"
                        class="pt-0"
                        style="cursor: pointer"
                        >Je souhaite un montage vidéo</label
                      >
                      <v-checkbox
                        id="hasPostProd"
                        on-icon="mdi-movie-play"
                        v-model="formulaire.drone.mariage.hasPostProd"
                        :true-value="true"
                        :false-value="false"
                        color="secondary"
                        label=""
                        hide-details
                        style="display: inline-block"
                        class="ml-3 mt-0 pt-0"
                      ></v-checkbox>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-tab-item>

            <v-tab-item key="PrestationDeveloppement">
              <v-row class="mt-3">
                <v-col cols="12" class="pt-3 pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="selectDevValidation"
                    :rules="{ required: isDeveloppement }"
                  >
                    <v-select
                      v-model="formulaire.developpement.selectDev"
                      :items="listPrestaDev"
                      label="Prestations demandées"
                      multiple
                      chips
                      filled
                      item-text="text"
                      item-value="value"
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12" class="pt-0 pb-3">
                  <v-menu
                    ref="menuDatesRangeDev"
                    v-model="menuDatesRangeDev"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="RangeDateValidation"
                        :rules="{ required: isDeveloppement }"
                      >
                        <v-text-field
                          readonly
                          v-model="DatesRangeDevFormatted"
                          label="Dates souhaitées *"
                          prepend-icon="mdi-calendar-start"
                          v-bind="attrs"
                          :error-messages="errors"
                          v-on="on"
                          hint="Vous pouvez choisir une période précise ou seulement une date de début"
                          persistent-hint
                          class="mt-3"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      :min="new Date().toISOString().substr(0, 10)"
                      locale="fr"
                      first-day-of-week="1"
                      range
                      v-model="formulaire.developpement.datesRangeDev"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>

          <div class="my-2">
            <small>* champs obligatoire</small>
          </div>

          <div class="text-center text-sm-left">
            <v-tooltip top :disabled="!invalid">
              <template v-slot:activator="{ on }">
                <span v-on="on" class="d-inline-block mt-5 mr-5">
                  <v-btn
                    class=""
                    color="secondary"
                    :disabled="
                      invalid ||
                      (isMariage &&
                        !formulaire.drone.mariage.hasMairie &&
                        !formulaire.drone.mariage.hasEglise &&
                        !formulaire.drone.mariage.hasReception)
                    "
                    @click="etapeFormulaire++"
                  >
                    Suivant
                  </v-btn>
                </span>
              </template>
              <span>Veuillez remplir tous les champs obligatoires</span>
            </v-tooltip>
            <span class="d-inline-block mt-5 mr-5">
              <v-btn text @click="etapeFormulaire = etapeFormulaire - 1">
                Retour
              </v-btn>
            </span>
          </div>
        </validation-observer>
      </form>
    </v-stepper-content>

    <v-stepper-step
      :editable="etapeFormulaire > 3 && !sendingForm"
      :complete="etapeFormulaire > 3"
      step="3"
    >
      <span class="primary--text font-weight-medium">Complément</span>
    </v-stepper-step>

    <v-stepper-content step="3">
      <form class="mx-3">
        <validation-observer ref="observerDemande" v-slot="{ invalid }">
          <div class="my-2">
            Vous pouvez détailler votre demande et décrire ce que vous
            souhaitez.
          </div>

          <validation-provider
            v-slot="{ errors }"
            name="message"
            :rules="{ required: isSurMesure || isDeveloppement }"
          >
            <v-textarea
              v-on:keyup="validateDemande"
              v-model="formulaire.informations.message"
              :error-messages="errors"
              :label="
                'Message' +
                (isSurMesure || isDeveloppement ? ' *' : ' (facultatif)')
              "
              required
            ></v-textarea>
          </validation-provider>

          <div class="text-center text-sm-left">
            <v-tooltip top :disabled="!invalid">
              <template v-slot:activator="{ on }">
                <span v-on="on" class="d-inline-block mt-5 mr-5">
                  <v-btn
                    :loading="sendingForm"
                    :disabled="invalid || sendingForm"
                    color="primary"
                    @click="submit"
                  >
                    Envoyer
                  </v-btn>
                </span>
              </template>
              <span>Veuillez remplir tous les champs obligatoires</span>
            </v-tooltip>
            <span class="d-inline-block mt-5 mr-5">
              <v-btn
                :disabled="sendingForm"
                text
                @click="etapeFormulaire = etapeFormulaire - 1"
              >
                Retour
              </v-btn>
            </span>
          </div>
        </validation-observer>
      </form>
    </v-stepper-content>
  </v-stepper>
</template>


<script>
import { mapGetters } from "vuex";

import { required, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
  Validator,
} from "vee-validate";
extend("regex", regex);

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "Ce champ est obligatoire",
});

extend("max", {
  ...max,
  message: "Ce champ ne doit pas dépasser {length} caractères",
});

extend("email", {
  ...email,
  message: "L'adresse e-mail doit être valide",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    sendingForm: {
      type: Boolean,
      default: false,
    },
    formSend: {
      type: Boolean,
      default: false,
    },
  },
  data: (vm) => ({
    tabPrestation: null,
    etapeFormulaire: 1,
    formulaire: {
      informations: {
        firstname: "",
        lastname: "",
        email: "",
        phoneNumber: "",
        isCompany: false,
        company: "",
        message: "",
        optIn: null,
      },

      drone: {
        prestationSelected: null,
        searchAddressDrone: null,
        eventDate: null,
        mariage: {
          hasMairie: false,
          searchAddressMairie: null,

          hasEglise: false,
          searchAddressEglise: null,

          hasReception: false,
          searchAddressReception: null,

          hasPostProd: false,
        },

        immobilier: {
          toggleImmobilier: undefined,
          descriptionBien: null,
        },
      },

      developpement: {
        selectDev: null,
        datesRangeDev: null,
      },
    },

    isLoadingAddressMairie: false,
    modelAddressMairie: null,
    hideNoDataAddressMairie: true,
    searchResultsMairie: [],

    isLoadingAddressEglise: false,
    modelAddressEglise: null,
    //AddressEglise: "",
    hideNoDataAddressEglise: true,
    searchResultsEglise: [],

    isLoadingAddressReception: false,
    modelAddressReception: null,
    //AddressReception: "",
    hideNoDataAddressReception: true,
    searchResultsReception: [],

    serviceGoogle: null,

    listPrestaDev: [
      { value: "consulting", text: "Expertise, Conseil & Conception" },
      { value: "back", text: "Développeur PHP" },
      { value: "bdd", text: "Gestion base de données" },
      { value: "front", text: "Développeur front (HTML/CSS/JS)" },
    ],

    eventDateFormatted: null,
    menuEventDate: false,

    DatesRangeDevFormatted: null,
    menuDatesRangeDev: false,

    isLoadingAddressDrone: false,
    modelAddressDrone: null,
    AddressDrone: "",
    hideNoDataAddressDrone: true,
    searchResultsDrone: [],

    descriptionToggleImmobilierSelected: "",
    descriptionsToggleImmobilier: {
      maison: "Maison / Villa / Château",
      immeuble: "Immeuble / Copropriété / Construction",
      entreprise: "Entreprise / Entrepôt / Site de production",
      autre: "Autre type bien",
    },

    prestationsVSelect: [
      { header: "Immobilier ou entreprise" },
      { name: "Photos aériennes en drone", value: "immo_photo_drone" },
      { name: "Vidéos aériennes en drone", value: "immo_video_drone" },
      { name: "Vidéos d'intérieur", value: "immo_video_sol" },
      { name: "Pack aérien et intérieur", value: "immo_pack" },
      { divider: true },
      { header: "Autre" },
      { name: "Mariage", value: "mariage_drone" },
      { name: "Évènement", value: "evenement_drone" },
      { name: "Tourisme", value: "tourisme_drone" },
      { name: "Prestation drone sur mesure", value: "sur_mesure" },
    ],
    prestationsRoute: {
      immo_photo_drone: "drone#tarifs",
      immo_video_drone: "drone#tarifs",
      immo_video_sol: "drone#tarifs",
      immo_pack: "drone#tarifs",
      mariage_drone: "drone/prestation-mariage",
      evenement_drone: "drone/prestation-evenement",
      tourisme_drone: "drone/prestation-tourisme",
      sur_mesure: "drone#tarifs_autre",
    },

    googleMapsApiScript: "https://maps.googleapis.com/maps/api/js?key=",
    googleMapsApiKey: "AIzaSyB79a2CjqF8IQarvM9c8R9YL9YI8XIVdmk",
  }),
  mounted() {
    if (!document.head.innerHTML.includes(this.googleMapsApiScript)) {
      let googleApiPlaces = document.createElement("script");
      googleApiPlaces.async = true;
      googleApiPlaces.defer = true;

      let vm = this;

      googleApiPlaces.onload = function () {
        vm.initMaps();
      };

      googleApiPlaces.setAttribute(
        "src",
        this.googleMapsApiScript + this.googleMapsApiKey + "&libraries=places"
      );

      document.head.appendChild(googleApiPlaces);
    } else {
      this.initMaps();
    }
  },
  computed: {
    ...mapGetters(["isMobile"]),
    itemsAddressMairie() {
      return this.searchResultsMairie.map((entry) => {
        const Adresse = entry;
        return Object.assign({}, entry, { Adresse });
      });
    },
    itemsAddressEglise() {
      return this.searchResultsEglise.map((entry) => {
        const Adresse = entry;
        return Object.assign({}, entry, { Adresse });
      });
    },
    itemsAddressReception() {
      return this.searchResultsReception.map((entry) => {
        const Adresse = entry;
        return Object.assign({}, entry, { Adresse });
      });
    },
    itemsAddressDrone() {
      return this.searchResultsDrone.map((entry) => {
        const Adresse = entry;
        return Object.assign({}, entry, { Adresse });
      });
    },
    isMariage() {
      return (
        this.isAudiovisuel &&
        this.formulaire.drone.prestationSelected == "mariage_drone"
      );
    },
    isEvenement() {
      return (
        this.isAudiovisuel &&
        this.formulaire.drone.prestationSelected == "evenement_drone"
      );
    },
    isImmo() {
      return (
        this.isAudiovisuel &&
        this.formulaire.drone.prestationSelected != null &&
        this.formulaire.drone.prestationSelected.includes("immo")
      );
    },
    isSurMesure() {
      return (
        this.isAudiovisuel &&
        this.formulaire.drone.prestationSelected == "sur_mesure"
      );
    },

    isAudiovisuel() {
      return this.tabPrestation == 0;
    },

    isDeveloppement() {
      return this.tabPrestation == 1;
    },

    eventDate() {
      return this.formulaire.drone.eventDate;
    },

    searchAddressDrone() {
      return this.formulaire.drone.searchAddressDrone;
    },

    searchAddressMairie() {
      return this.formulaire.drone.mariage.searchAddressMairie;
    },
    searchAddressEglise() {
      return this.formulaire.drone.mariage.searchAddressEglise;
    },
    searchAddressReception() {
      return this.formulaire.drone.mariage.searchAddressReception;
    },

    toggleImmobilier() {
      return this.formulaire.drone.immobilier.toggleImmobilier;
    },

    datesRangeDev() {
      return this.formulaire.developpement.datesRangeDev;
    },
  },
  watch: {
    eventDate(val) {
      this.eventDateFormatted = this.formatDate(
        this.formulaire.drone.eventDate
      );
    },
    datesRangeDev(val) {
      if (this.formulaire.developpement.datesRangeDev[1] != null) {
        this.DatesRangeDevFormatted =
          "du " +
          this.formatDate(this.formulaire.developpement.datesRangeDev[0]) +
          " au " +
          this.formatDate(this.formulaire.developpement.datesRangeDev[1]);
      } else {
        this.DatesRangeDevFormatted =
          "à partir du " +
          this.formatDate(this.formulaire.developpement.datesRangeDev[0]);
      }
    },
    searchAddressMairie(val) {
      if (this.formulaire.drone.mariage.searchAddressMairie == null)
        this.modelAddressMairie = null;
      this.searchAddress(val, "mairie");
    },
    searchAddressEglise(val) {
      if (this.formulaire.drone.mariage.searchAddressEglise == null)
        this.modelAddressEglise = null;
      this.searchAddress(val, "eglise");
    },
    searchAddressReception(val) {
      if (this.formulaire.drone.mariage.searchAddressReception == null)
        this.modelAddressReception = null;
      this.searchAddress(val, "reception");
    },
    searchAddressDrone(val) {
      if (this.formulaire.drone.searchAddressDrone == null)
        this.modelAddressDrone = null;
      this.searchAddress(val, "drone");
    },
    toggleImmobilier(val) {
      this.descriptionToggleImmobilierSelected = this.descriptionsToggleImmobilier[
        val
      ];
    },
  },
  methods: {
    submit() {
      this.$emit("submit", {
        formulaire: this.formulaire,
        type: this.isAudiovisuel ? "audiovisuel" : "developpement",
      });
    },
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll("input"));
      const index = inputs.indexOf(e.target);

      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
    validateDemande() {
      this.$refs.observerDemande.validate();
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date || date == "") return null;

      const [day, month, year] = date.split("/");
      let parseDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
        2,
        "0"
      )}`;
      let testDate = new Date(parseDate);
      if (
        testDate == "Invalid Date" ||
        testDate.getTime() < new Date().getTime()
      )
        return null;
      return parseDate;
    },
    searchAddress(val, item) {
      /*Items have already been loaded*/

      /*Items have already been requested*/
      if (val) {
        if (item == "mairie") {
          this.isLoadingAddressMairie = true;
          this.hideNoDataAddressMairie = false;
        }
        if (item == "eglise") {
          this.isLoadingAddressEglise = true;
          this.hideNoDataAddressEglise = false;
        }
        if (item == "reception") {
          this.isLoadingAddressReception = true;
          this.hideNoDataAddressReception = false;
        }
        if (item == "drone") {
          this.isLoadingAddressDrone = true;
          this.hideNoDataAddressDrone = false;
        }

        this.serviceGoogle.getPlacePredictions(
          {
            input: val,
            componentRestrictions: { country: "fr" },
          },
          this.displaySuggestions
        );
      } else {
        if (item == "mairie") {
          this.searchResultsMairie = [];
          this.hideNoDataAddressMairie = true;
        }
        if (item == "eglise") {
          this.searchResultsEglise = [];
          this.hideNoDataAddressEglise = true;
        }
        if (item == "reception") {
          this.searchResultsReception = [];
          this.hideNoDataAddressReception = true;
        }
        if (item == "drone") {
          this.searchResultsDrone = [];
          this.hideNoDataAddressDrone = true;
        }
      }
    },
    initMaps() {
      this.serviceGoogle = new window.google.maps.places.AutocompleteService();
    },
    displaySuggestions(predictions, status) {
      let searchResults;

      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        searchResults = [];
      } else {
        searchResults = predictions.map((prediction) => prediction.description);
      }

      if (this.isLoadingAddressMairie) {
        this.isLoadingAddressMairie = false;
        this.searchResultsMairie = searchResults;
      }
      if (this.isLoadingAddressEglise) {
        this.isLoadingAddressEglise = false;
        this.searchResultsEglise = searchResults;
      }
      if (this.isLoadingAddressReception) {
        this.isLoadingAddressReception = false;
        this.searchResultsReception = searchResults;
      }
      if (this.isLoadingAddressDrone) {
        this.isLoadingAddressDrone = false;
        this.searchResultsDrone = searchResults;
      }
    },
  },
};
</script>

<style>
#formulaireDevis
  .v-stepper__step__step
  i.v-icon.mdi-pencil[aria-hidden="true"]::before {
  content: "\F012C";
}

#toggleImmobilier .v-btn.v-item--active::before {
  opacity: 0.5 !important;
}

#toggleImmobilier .v-btn.v-item--active i.v-icon {
  color: white !important;
}
</style>}
