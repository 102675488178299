<template>
  <v-container class="mt-15">
    <v-row>
      <v-col cols="12" md="4" class="pt-8 mb-5">
        <Coordonnees />
      </v-col>
      <v-col cols="12" md="8" class="pt-8 mb-5">
        <Formulaires />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import Formulaires from "@/components/formulaires/Formulaires.vue";
import Coordonnees from "@/components/Coordonnees.vue";

export default {
  name: "Contact",
  metaInfo() {
    return {
      title: " - Me contacter",
      meta: [
        {
          name: "description",
          content:
            "Contactez-moi via le formulaire proposé ou par mail ou par téléphone. Vous pouvez aussi me suivre sur les différents réseaux sociaux.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.dev-et-drone.fr/contact"
        },
      ],
    };
  },
  components: {
    Formulaires,
    Coordonnees,
  },
};
</script>