<template>
  <v-stepper
    v-if="!formSend"
    id="formulaireInfo"
    v-model="etapeFormulaire"
    vertical
  >
    <v-stepper-step
      :editable="etapeFormulaire > 1 && !sendingForm"
      :complete="etapeFormulaire > 1"
      step="1"
    >
      <span class="primary--text font-weight-medium">Vos coordonnées</span>
    </v-stepper-step>

    <v-stepper-content step="1">
      <validation-observer ref="observerCoordonnees" v-slot="{ invalid }">
        <form class="mx-3">
          <v-row>
            <v-col cols="12" lg="6" md="12" sm="6" xs="12" class="pt-2 pb-0">
              <validation-provider
                v-slot="{ errors }"
                name="firstname"
                rules="required|max:255"
              >
                <v-text-field
                  @keydown.enter="focusNext"
                  v-model="formulaire.informations.firstname"
                  :error-messages="errors"
                  label="Prénom *"
                  required
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="6" md="12" sm="6" xs="12" class="pt-2 pb-0">
              <validation-provider
                v-slot="{ errors }"
                name="lastname"
                rules="required|max:255"
              >
                <v-text-field
                  @keydown.enter="focusNext"
                  v-model="formulaire.informations.lastname"
                  :error-messages="errors"
                  label="Nom *"
                  required
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" lg="6" md="12" sm="6" xs="12" class="pt-2 pb-0">
              <validation-provider
                v-slot="{ errors }"
                name="email"
                rules="required|email"
              >
                <v-text-field
                  type="email"
                  @keydown.enter="focusNext"
                  v-model="formulaire.informations.email"
                  :error-messages="errors"
                  label="Adresse e-mail *"
                  required
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="6" md="12" sm="6" xs="12" class="pt-2 pb-0">
              <validation-provider
                v-slot="{ failedRules }"
                name="phoneNumber"
                :rules="{
                  regex: '^((\\+|00)33|0)\\s*[1-9](?:[\\s.-]*\\d{2}){4}$',
                }"
              >
                <v-text-field
                  type="number"
                  @keydown.enter="focusNext"
                  v-model="formulaire.informations.phoneNumber"
                  :error-messages="
                    failedRules.regex
                      ? 'Le numéro de téléphone doit être au format français'
                      : ''
                  "
                  label="Numéro de téléphone"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" lg="6" md="12" sm="6" xs="12" class="pt-2 pb-0">
              <v-checkbox
                v-model="formulaire.informations.isCompany"
                class=""
                :true-value="true"
                :false-value="false"
                color="secondary"
                label="Je représente une entreprise"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" lg="6" md="12" sm="6" xs="12" class="pt-2 pb-0">
              <validation-provider
                v-slot="{ errors }"
                name="company"
                :rules="{
                  max: 255,
                  required: formulaire.informations.isCompany,
                }"
              >
                <v-text-field
                  @keydown.enter="focusNext"
                  v-show="formulaire.informations.isCompany"
                  v-model="formulaire.informations.company"
                  :error-messages="errors"
                  label="Nom de l'entreprise *"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>

          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="optIn"
          >
            <v-checkbox
              v-model="formulaire.informations.optIn"
              :error-messages="errors"
              :true-value="true"
              :false-value="null"
              label="J'accepte que mes informations personnelles soient traitées dans le cadre de ma demande *"
              type="checkbox"
              required
            ></v-checkbox>
          </validation-provider>

          <div class="my-2">
            <small>* champs obligatoire</small>
          </div>
          <div class="text-center text-sm-left">
            <v-tooltip top :disabled="!invalid">
              <template v-slot:activator="{ on }">
                <span v-on="on" class="d-inline-block mt-5">
                  <v-btn
                    class=""
                    color="secondary"
                    :disabled="invalid"
                    @click="etapeFormulaire++"
                  >
                    Suivant
                  </v-btn>
                </span>
              </template>
              <span>Veuillez remplir tous les champs obligatoires</span>
            </v-tooltip>
          </div>
        </form>
      </validation-observer>
    </v-stepper-content>

    <v-stepper-step
      :editable="etapeFormulaire > 2 && !sendingForm"
      :complete="etapeFormulaire > 2"
      step="2"
    >
      <span class="primary--text font-weight-medium">Votre demande</span>
    </v-stepper-step>

    <v-stepper-content step="2">
      <form class="mx-3">
        <validation-observer ref="observerDemande" v-slot="{ invalid }">
          <validation-provider
            v-slot="{ errors }"
            name="subject"
            rules="required|max:255"
          >
            <v-text-field
              @keydown.enter="focusNext"
              v-model="formulaire.informations.subject"
              :error-messages="errors"
              label="Sujet *"
              required
            ></v-text-field>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            name="message"
            rules="required"
          >
            <v-textarea
              v-on:keyup="validateDemande"
              v-model="formulaire.informations.message"
              :error-messages="errors"
              label="Message *"
              required
            ></v-textarea>
          </validation-provider>

          <div class="my-2">
            <small>* champs obligatoire</small>
          </div>
          <div class="text-center text-sm-left">
            <v-tooltip top :disabled="!invalid">
              <template v-slot:activator="{ on }">
                <span v-on="on" class="d-inline-block mt-5 mr-5">
                  <v-btn
                    :loading="sendingForm"
                    :disabled="invalid || sendingForm"
                    color="primary"
                    @click="submit"
                  >
                    Envoyer
                  </v-btn>
                </span>
              </template>
              <span>Veuillez remplir tous les champs obligatoires</span>
            </v-tooltip>
            <span class="d-inline-block mt-5 mr-5">
              <v-btn
                :disabled="sendingForm"
                text
                @click="etapeFormulaire = etapeFormulaire - 1"
              >
                Retour
              </v-btn>
            </span>
          </div>
        </validation-observer>
      </form>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import { required, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
  Validator,
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "Ce champ est obligatoire",
});

extend("max", {
  ...max,
  message: "Ce champ ne doit pas dépasser {length} caractères",
});

extend("email", {
  ...email,
  message: "L'adresse e-mail doit être valide",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    sendingForm: {
      type: Boolean,
      default: false,
    },
    formSend: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    etapeFormulaire: 1,
    formulaire: {
      informations: {
        firstname: "",
        lastname: "",
        email: "",
        phoneNumber: "",
        isCompany: false,
        company: "",
        subject: "",
        message: "",
        optIn: null,
      },
    },
  }),
  methods: {
    submit() {
      this.$emit("submit", {
        formulaire: this.formulaire,
        type: "information",
      });
    },
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll("input"));
      const index = inputs.indexOf(e.target);

      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
    validateDemande() {
      this.$refs.observerDemande.validate();
    },
  },
};
</script>

<style>
#formulaireInfo
  .v-stepper__step__step
  i.v-icon.mdi-pencil[aria-hidden="true"]::before {
  content: "\F012C";
}
</style>}
