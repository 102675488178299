<template>
	<v-card elevation="3">
		<v-row>
			<v-col cols="12" xs="12" sm="6" md="12">
				<video id="logoAnimation" width="100%" disableRemotePlayback autoplay muted poster="../assets/poster_animation_logo_blanc.jpg">
					<source :src="require('../assets/logos/animation_logo.mp4')"
					type="video/mp4">
				</video>
		<!--<v-card-title class="text-center justify-center py-6">
			<h1 class="text-h4 primary--text">
				Coordonnées
			</h1>
		</v-card-title>-->
		<v-card-text class="text-justify py-0">
			N’hésitez pas à me contacter, je vous répondrai dans les meilleurs délais.
			Vous pouvez utiliser le formulaire ci-contre pour me faire part de votre demande.
		</v-card-text>
	</v-col>
	<v-col cols="12" xs="12" sm="6" md="12" class="flexParentCenterCenter">
		<v-list width="100%">


			<v-list-item>
				<v-list-item-icon>
					<v-icon color="accent">
						mdi-youtube
					</v-icon>
				</v-list-item-icon>

				<v-list-item-content>
					<v-list-item-title><a href="https://www.youtube.com/channel/UCV8mojjJjrqlm5ehNC5yqrg" target="_blank">La chaîne Youtube</a></v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-divider inset></v-divider>

			<v-list-item>
				<v-list-item-icon>
					<v-icon color="primary">
						mdi-facebook
					</v-icon>
				</v-list-item-icon>

				<v-list-item-content>
					<v-list-item-title><a href="https://fb.me/devetdrone" target="_blank">La Page Facebook</a></v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-divider inset></v-divider>

			<v-list-item>
				<v-list-item-icon>
					<v-icon color="accent">
						mdi-instagram
					</v-icon>
				</v-list-item-icon>

				<v-list-item-content>
					<v-list-item-title><a href="https://www.instagram.com/devetdrone" target="_blank">Le compte Instagram</a></v-list-item-title>
				</v-list-item-content>
			</v-list-item>


			<v-divider ></v-divider>

			<v-list-item>
				<v-list-item-icon>
					<v-icon color="secondary">
						mdi-email
					</v-icon>
				</v-list-item-icon>

				<v-list-item-content>
					<v-list-item-title><a href="mailto:contact@dev-et-drone.fr">contact@dev-et-drone.fr</a></v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-divider inset></v-divider>

			<v-list-item>
				<v-list-item-icon>
					<v-icon color="info">
						mdi-phone
					</v-icon>
				</v-list-item-icon>

				<v-list-item-content>
					<v-list-item-title><a href="tel:0749359827">07 49 35 98 27</a></v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-col>
</v-row>
</v-card>
</template>

<script>
	export default {
		name: 'HelloWorld',

		data: () => ({
			videoCanPlayThrough: false

		}),
		methods: {
			showLogo: function(){
				var video = document.getElementById("logoAnimation");
				video.autoplay=false;
				video.load();
			}
		},
		mounted(){

			let vm = this; 
			var video = document.getElementById("logoAnimation");
			video.addEventListener("ended", () => vm.showLogo())
			video.addEventListener("waiting", () => vm.showLogo())
			video.addEventListener("canplaythrough", () => vm.videoCanPlayThrough = true)
			setTimeout(function()
			{
				if (!vm.videoCanPlayThrough)
				{
					video.poster = require("../assets/logos/animation_logo_final.jpg");
					vm.showLogo()
				}
				else
				{
					if (video.paused)
					{
						video.play();
					}
					video.poster = require("../assets/logos/animation_logo_final.jpg");
				}
			},1000);

		},

	}
</script>

<style scoped>

a{
	text-decoration :none
}

a:hover{
	text-decoration:underline;
}

</style>