<template>
  <v-container id="realisations" class="anchor_tags">
    <h2 class="text-h3 primary--text text-xs-center my-5">Réalisations</h2>
    <div class="text-justify">
      Dev & Drone est une entreprise récente qui a déjà réalisé des prestations
      pour des clients particuliers comme pour des clients profesionnels mais à diffusion interne ou privée.
    </div>
    <div class="mt-3 text-justify">
      Ci-dessous, une bande démo de présentation de ce qu'il est possible de
      faire en drone.
    </div>
    <div
      id="ytplayer"
      class="my-10 flexParentCenterCenter"
      style="overflow: hidden"
    >
      <youtube
        title="bande Démo Dev & Drone"
        ref="youtube"
        :video-id="videoId"
        :player-vars="playerVars"
        :fitParent="true"
        :resize="true"
        :resizeDelay="0"
        @playing="playing"
      ></youtube>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Realisations",

  data: () => ({
    minHeightYTPlayer: 0,
    videoId: "iozf33rHEOI",
    playerVars: {
      color: "white",
      controls: 2,
      frameborder: 0,
      modestbranding: 1,
      showinfo: 0,
      origin: window.location.host,
    },
  }),
  created() {
    this.show = true;
  },
  mounted() {
    let height =
      document.querySelector("#ytplayer").offsetWidth * 0.5625 + "px";
    document.getElementById("ytplayer").style.height = height;
    setTimeout(function () {
      document.getElementById("ytplayer").style.height = null;
    }, 3000);
  },
  destroyed() {},
  computed: {},
  methods: {
    playing() {
      console.log("o/ we are watching!!!");
    },
  },
};
</script>

<style scoped>
#realisations {
  min-height: 100vh !important;
  min-height: calc(100 * var(--vh) - 56px) !important;
}
</style>