<template>
  <div>
    <div class="pa-0" style="position: absolute; width: 100%">
      <v-img
        class="hauteur100vh"
        :src="require('@/assets/landscapes/landscape-404.jpg')"
        :lazy-src="require('@/assets/landscapes/landscape-404-lazy.jpg')"
        style="position: absolute; width: 100%"
      ></v-img>
    </div>
    <div
      class="hauteur100vh pa-5 flexParentCenterCenter text-center white--text mt-0"
      style="
        position: absolute;
        top: 0;
        width: 100%;
        background: black;
        opacity: 0;
      "
    ></div>
    <div
      class="hauteur100vh pa-5 flexParentCenterCenter text-center white--text mt-0"
      style="position: absolute; top: 0; width: 100%"
    >
      <div>
        <div class="text text-404">404</div>
        <div class="text-h5 text-sm-h3 text">Page non trouvée</div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "NotFound",
  metaInfo() {
    return {
      title: " - Page non trouvée",
    };
  },
  mounted: function () {},
};
</script>



<style scoped>
.hauteur100vh {
  height: 100vh; /*à laisser si calc non supporté*/
  height: calc(100 * var(--vh) - 56px);
}

.text {
  color: #cdd4de;
  opacity: 0.6;
  text-shadow: 1px 1px 2px #7c7e83, 0 0 1em #7c7e83, 0 0 0.2em #7c7e83;
}
.text::selection {
  background: #cdd4de;
}

.text-404 {
  font-weight: 900;
  font-size: 165px;
  line-height: 1;
}

@media screen and (max-width: 600px) {
  .text-404 {
    font-weight: 900;
    font-size: 100px;
    line-height: 1;
  }
}
</style>