<template>
  <div class="anchor_tags">
    <v-container
      fluid
      id="containerCarousel"
      class="pa-0"
      style="position: absolute"
    >
      <v-carousel
        :hide-delimiters="isMobile"
        :touchless="true"
        v-model="carousel"
        continuous
        :cycle="isCycle"
        interval="5000"
        :show-arrows="false"
        hide-delimiter-background
        delimiter-icon="mdi-minus"
        height="100%"
      >
        <v-carousel-item
          height="100%"
          v-for="(item, i) in items"
          :key="i"
          :src="item.src"
          :lazy-src="item.lazy"
        >
        </v-carousel-item>
      </v-carousel>
      <div
        class="pa-5 flexParentCenterCenter text-center mt-0"
        id="titleCarousel"
        style="position: absolute; top: 0; width: 100%"
      >
        <div
          class="text-h5 text-sm-h2 text-lg-h1 font-weight-medium white--text text-center"
          style="opacity: 0.8"
        >
          <div class="">
            VOYEZ PLUS <span class="noBreakWord">LOIN ...</span>
          </div>
          <div class="mt-2" id="transitionText">.</div>
          <div id="divFloatBtn">
            <v-btn fab class="secondary" style="opacity: 0.8" @click="scroll">
              <v-icon large color="white">mdi-chevron-down</v-icon>
            </v-btn>
            <div v-if="!isMobile" class="mt-12"></div>
            <div v-if="isMobile" class="mt-3"></div>
          </div>
        </div>
      </div>
    </v-container>
    <div id="carousel100vh" style="height: 100vh; width: 100%"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import goTo from 'vuetify/es5/services/goto'

export default {
  name: "carousel",

  data: () => ({
    carousel: null,
    move: [],
    drag: false,
    touch: false,
    currentMove: null,
    isCycle: true,
    timerToRestartCycle: null,
    items: [
      {
        src: require("@/assets/landscapes/landscape_8.jpg"),
        lazy: require("@/assets/landscapes/landscape_8-lazy.jpg"),
        text: "EN PAYS DE LA LOIRE",
      },
      {
        src: require("@/assets/landscapes/landscape_5.jpg"),
        lazy: require("@/assets/landscapes/landscape_5-lazy.jpg"),
        text: "A NANTES",
      },
      {
        src: require("@/assets/landscapes/landscape_1.jpg"),
        lazy: require("@/assets/landscapes/landscape_1-lazy.jpg"),
        text: "EN BRETAGNE",
      },
      {
        src: require("@/assets/landscapes/landscape_7.jpg"),
        lazy: require("@/assets/landscapes/landscape_7-lazy.jpg"),
        text: "DANS L'IMMOBILIER",
      },
      {
        src: require("@/assets/landscapes/mariage.jpg"),
        lazy: require("@/assets/landscapes/mariage-lazy.jpg"),
        text: "DANS UN MARIAGE",
      },
      {
        src: require("@/assets/landscapes/landscape_3.jpg"),
        lazy: require("@/assets/landscapes/landscape_3-lazy.jpg"),
        text: "EN MONTAGNE",
      },
      {
        src: require("@/assets/landscapes/landscape_4.jpg"),
        lazy: require("@/assets/landscapes/landscape_4-lazy.jpg"),
        text: "DANS LA VALLÉE",
      },
      {
        src: require("@/assets/landscapes/landscape_11.jpg"),
        lazy: require("@/assets/landscapes/landscape_11-lazy.jpg"),
        text: "EN BORD DE MER",
      },
      {
        src: require("@/assets/landscapes/landscape_2.jpg"),
        lazy: require("@/assets/landscapes/landscape_2-lazy.jpg"),
        text: "AU COUCHER DE SOLEIL",
      },
      /*{
        src: require("@/assets/landscapes/landscape_6.jpg"),
        lazy: require("@/assets/landscapes/landscape_6-lazy.jpg"),
        text: "AU COUCHER DE SOLEIL",
      },*/
      {
        src: require("@/assets/landscapes/landscape_9.jpg"),
        lazy: require("@/assets/landscapes/landscape_9-lazy.jpg"),
        text: "A COLLIOURE",
      },
      /*{
        src: require("@/assets/landscapes/landscape_10.jpg"),
        lazy: require("@/assets/landscapes/landscape_10-lazy.jpg"),
        text: "DANS L'IMMOBILIER",
      },*/
    ],
  }),
  watch: {
    carousel() {
      this.animationText();
    },
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
  mounted() {
    // For touch devices
    document
      .getElementById("titleCarousel")
      .addEventListener("touchmove", (e) => {
        this.timerToRestartCycle = null;
        this.drag = false;
        this.touch = true;
        this.logic(e);
      });
    window.addEventListener("touchend", (e) => {
      this.changeCarousel();
      this.drag = false;
      this.touch = false;
      this.move = [];
    });

    // For non-touch devices
    /*document
      .getElementById("titleCarousel")
      .addEventListener("mousedown", (e) => {
        console.log("MOUSEDOWN")
        this.drag = true;
        this.touch = false;
        this.logic(e);
      });
    document
      .getElementById("titleCarousel")
      .addEventListener("mousemove", (e) => {
        console.log("MOUSEMOVE")
        console.log(this.drag)
        this.drag ? this.logic(e) : null;
      });
    window.addEventListener("mouseup", (e) => {
        console.log("MOUSEUP")
      this.drag = false;
      this.touch = false;
      this.changeCarousel();
      this.move = [];
    });*/
  },
  methods: {
    animationText() {
      let elem = document.getElementById("transitionText");
      let vm = this;

      elem.classList.add("opacity0");
      setTimeout(function () {
        elem.innerHTML = vm.items[vm.carousel].text;
        elem.classList.remove("opacity0");
      }, 500);
    },
    scroll(){
      goTo(document.getElementById("carousel100vh").offsetHeight, {
          duration: 1000,
          offset: document.getElementsByClassName("v-toolbar__content")[0].offsetHeight,
          easing: "easeInOutCubic",
        });
    },
    logic(e) {
      this.currentMove = this.touch ? e.touches[0].clientX : e.clientX;
      if (this.move.length == 0) {
        this.move.push(this.currentMove);
      }
      //console.log(this.move[this.move.length - 1]);
      //console.log(this.currentMove);
    },
    changeCarousel() {
      //console.log("CHANGE")
      //console.log(this.currentMove);
      if (this.move[this.move.length - 1] - this.currentMove < -20) {
        if (this.carousel == 0) {
          this.carousel = this.items.length - 1;
        } else {
          this.carousel = this.carousel - 1;
        }
        this.isCycle = false;
        let vm = this;
        this.timerToRestartCycle = setTimeout(function () {
          vm.isCycle = true;
        }, 5000);
      }
      if (this.move[this.move.length - 1] - this.currentMove > 20) {
        if (this.carousel == this.items.length - 1) {
          this.carousel = 0;
        } else {
          this.carousel++;
        }
        this.isCycle = false;
        let vm = this;
        this.timerToRestartCycle = setTimeout(function () {
          vm.isCycle = true;
        }, 5000);
      }
    },
  },
};
</script>

<style scoped>
#containerCarousel,
#titleCarousel {
  width: 100%;
  height: 100vh; /*à laisser si calc non supporté*/
  height: calc(100 * var(--vh) - 56px);
  margin-top: 0; /*à laisser si calc non supporté*/
  margin-top: calc(56px);
  transition: 0.5s all ease-out;
}

#divFloatBtn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

#transitionText {
  opacity: 1;
  transition: opacity 0.5s;
}
.opacity0 {
  opacity: 0 !important;
}
</style>