<template>
  <div class="paddingHeader">
    <div class="mx-5 fade-in scale-enlarge">
      <div class="box text-center mb-10 mt-13">
        <h1
          class="
            text-h6 text-sm-h5 text-md-h4
            secondary--text
            text-center
            fontCustom
            mb-3
            question
          "
        >
          <span class="px-3">Un drone à votre {{ event }} ?</span>
        </h1>
        <span
          class="
            text-h4 text-sm-h3 text-md-h2
            secondary--text
            text-center
            fontCustom
            reponse
          "
        >
          Laissez-vous tenter !
        </span>
      </div>
    </div>
    <v-container>
      <v-row class="fade-in translate-bottom">
        <v-col cols="12" lg="10" offset-lg="1">
          <div class="primary--text my-5 fontCustom titleCustom">
            Qu'est-ce que cela <span class="noBreakWord">apporte ?</span>
          </div>

          <v-row>
            <v-col cols="12" sm="12" md="8" lg="8">
              <div class="secondary--text text-subtitle-1 text-justify">
                Le drone donne la possibilité d'immortaliser votre
                {{ event }} avec des points de vus grandiose et uniques qui sont
                accessibles seulement avec un drone. Cela permet de prendre de
                la hauteur et d'avoir, par exemple sur une même photo tous les
                invités avec le lieu de réception en arrière-plan. La vidéo
                permet de faire découvrir les lieux vu du ciel qui donnera des
                images spectaculaires. Le drone permet aussi de suivre les
                mariés à la sortie de la mairie ou de l'église ainsi que
                l'arrivée au lieu de réception par exemple.
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4">
              <v-img
                :src="require('@/assets/mariage/mariage-3.jpg')"
                :lazy-src="require('@/assets/mariage/mariage-3-lazy.jpg')"
              >
              </v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="fade-in translate-bottom">
        <v-col cols="12" lg="10" offset-lg="1">
          <div class="primary--text mt-5 fontCustom titleCustom">
            Quelles sont les <span class="noBreakWord">limites ?</span>
          </div>
          <div class="secondary--text text-subtitle-1 text-justify mt-5">
            La réglementation française et européenne est stricte pour permettre
            à ce que les drones évoluent toujours en sécurité. Certaines zones
            de vols imposent le télépilote à avoir une dérogation (démarches
            administratives faites en amont) comme les agglomérations, les zones
            à proximité d'un aéroport ou d'une zone militaire.

            <div class="text-center mt-2">
              <v-btn
                small
                text
                to="/drone/prestation-telepilotage"
                class="text--secondary"
              >
                Plus d'informations ici
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="fade-in translate-bottom">
        <v-col cols="12" lg="10" offset-lg="1">
          <v-row>
            <v-col
              offset-md="4"
              offset-lg="4"
              class="primary--text mt-5 mb-2 py-0 fontCustom titleCustom"
            >
              Et la <span class="noBreakWord">météo ?</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="4" order="last" order-md="first">
              <v-img
                :src="require('@/assets/mariage/mariage-4.jpg')"
                :lazy-src="require('@/assets/mariage/mariage-4-lazy.jpg')"
              >
              </v-img>
            </v-col>
            <v-col cols="12" sm="12" md="8" order="first" order-md="last">
              <div class="secondary--text text-subtitle-1 text-justify">
                S'il pleut ou s'il y a du vent supérieur à 40km/h, le vol est
                impossible pour des questions de sécurité. Si tel est le cas,
                vous serez prévenu 48h avant votre {{ event }} et vous ne serez
                pas facturé et l'accompte vous sera remboursé.
              </div>
              <div class="secondary--text text-subtitle-1 text-justify mt-3">
                Les vols de nuits doivent obligatoirement faire l'objet d'une
                autorisation préalable par les autorités. Le drone peut voler
                sans autorisation préalable 30 minutes avant le lever du soleil
                et 30 minutes après le lever du soleil.
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="fade-in translate-bottom">
        <v-col cols="12" lg="10" offset-lg="1">
          <div class="primary--text mt-5 fontCustom titleCustom">
            L'expérience vous <span class="noBreakWord">tente ?</span>
          </div>
          <div class="secondary--text text-subtitle-1 text-justify mt-5">
            <ul>
              <li>
                Grâce au formulaire de demande de devis, présentez votre
                {{ event }}
                en quelques clics et quelques mots.
              </li>
              <li>
                Je reviendrais ensuite vers vous en vous proposant un devis
                après avoir vérifié que les zones de vols sont autorisées ou si
                une dérogation est possible.
              </li>
              <li>Un repérage en amont peut être effectué si besoin.</li>
              <li>
                Le jour de votre {{ event }}, la mise en place du drone est
                rapide (moins de 10 minutes). Un périmètre de sécurité pourra
                être mis en place si besoin.
              </li>
              <li>
                Ensuite, je peux m'occuper de la post-production et réaliser une
                vidéo de présentation.
              </li>
            </ul>
            <div class="text-center mt-8">
              <v-btn color="primary" text to="/drone#tarifs_evenements">
                VOIR LES TARIFS
              </v-btn>
            </div>
            <div class="text-center mt-3 mb-5">
              <v-btn color="primary" x-large to="/contact?devis">
                DEMANDER UN DEVIS
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ["mariage"],
  metaInfo() {
    return {
      title:
        " - " +
        (this.event == "mariage" ? "Mariage par drone" : "Service évènementiel"),
      meta: [
        {
          name: "description",
          content:
            "Dev & Drone propose de la prise de vues aériennes pour immortaliser votre" +
            (this.event == "mariage" ? "mariage" : "évènement") +
            " et découvrir les lieux vu du ciel qui donnera des images spectaculaires.",
        },
        {
          property: "og:image",
          content:
            "https://dev-et-drone.fr/static/og-image-" +
            (this.event == "mariage" ? "mariage" : "evenement") +
            ".jpg",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.dev-et-drone.fr/drone/prestation-"+(this.event == "mariage" ? "mariage" : "evenement")
        },
      ],
    };
  },
  components: {},
  data: () => ({ event: null }),
  mounted() {
    if (this.mariage) this.event = "mariage";
    else this.event = "évènement";
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.mariage) vm.event = "mariage";
      else vm.event = "évènement";
    });
  },
  destroyed() {},
  updated() {},
  methods: {},
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Great+Vibes");

.fontCustom {
  font-family: "Great Vibes", sans-serif !important;
}

.fontCustom.titleCustom {
  /*H4*/
  font-size: 2.3rem !important;
  font-weight: 400;
  line-height: 2.5rem;
  letter-spacing: 0.0073529412em !important;
}

.fontCustom.textCustom {
  /*H5*/
  font-size: 1.5rem !important;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal !important;
}

.box {
  border: 1px solid;
  background-color: white !important;
  border-color: #254460 !important;
  max-width: 450px;
  margin: 0 auto;
  padding-bottom: 10px;
  box-shadow: 10px 10px 10px #2544604d;
}

.box .question {
  margin: -20px auto;
}

.box .question span {
  background: white;
}

.v-image {
  max-height: none;
  min-height: none;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .box {
    max-width: 360px;
  }
  .box .question {
    margin: -15px auto;
  }

  .fontCustom.textCustom {
    /*H6*/
    font-size: 1.25rem !important;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em !important;
  }

  .fontCustom.titleCustom {
    /*H5*/
    font-size: 1.75rem !important;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: normal !important;
  }

  .v-image {
    max-height: 300px;
    min-height: 200px;
  }
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  .box {
    max-width: 380px;
  }
  .box .question {
    margin: -15px auto;
  }

  .v-image {
    height: 350px;
  }
}

@media screen and (min-width: 959px) and (max-width: 1263px) {
}

@media screen and (min-width: 1263px) and (max-width: 1903px) {
}
</style>