<template>
  <v-container fluid id="containerUnivers">
    <div id="ET" class="flexParentCenterCenter">
      <img :src="require('../assets/logos/&.svg')" />
    </div>
    <v-row id="rowUnivers">
      <v-col class="pa-0 colUnivers">
        <v-hover z-index="2">
          <template v-slot:default="{ hover }">
            <v-card class="mx-auto cardUnivers" id="cardUniversDeveloppement">
              <v-img
                z-index="0"
                v-on:click="goUniversDeveloppement"
                :src="require('../assets/developpement.jpg')"
                :lazy-src="require('../assets/developpement-lazy.jpg')"
                class="white--text align-end rounded-0"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              >
                <v-card-title
                  class="titleUnivers flexParentCenterCenter"
                  ><h2 class="text-h5 text-md-h4 text-lg-h3 text-xl-h2 font-weight-medium">DÉVELOPPEMENT</h2></v-card-title
                >
              </v-img>
              <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  absolute
                  color="#254460"
                  class="rounded-0"
                  z-index="3"
                  @click.native="goUniversDeveloppement"
                  style="cursor: pointer"
                >
                  <v-btn
                    v-on:click="goUniversDeveloppement"
                    class="button btnGoUnivers"
                    >Découvrir les prestations de DÉVELOPPEMENT</v-btn
                  >
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col class="pa-0 colUnivers">
        <v-hover z-index="2">
          <template v-slot:default="{ hover }">
            <v-card class="mx-auto cardUnivers" id="cardUniversDrone">
              <v-img
                z-index="0"
                v-on:click="goUniversDrone"
                :src="require('../assets/drone.jpg')"
                :lazy-src="require('../assets/drone-lazy.jpg')"
                class="white--text align-end rounded-0"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              >
                <v-card-title
                  class="titleUnivers flexParentCenterCenter"
                 ><h2 class="text-h5 text-md-h4 text-lg-h3 text-xl-h2 font-weight-medium">DRONE</h2></v-card-title
                >
              </v-img>
              <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  absolute
                  color="#254460"
                  class="rounded-0"
                  z-index="3"
                  @click.native="goUniversDrone"
                  style="cursor: pointer"
                >
                  <v-btn class="button btnGoUnivers" v-on:click="goUniversDrone"
                    >Découvrir les prestations AUDIOVISUELLES</v-btn
                  >
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>

    <video
      v-show="false"
      preload="auto"
      poster="../assets/poster_animation_logo_blanc.jpg"
    >
      <source
        :src="require('../assets/logos/animation_logo.mp4')"
        type="video/mp4"
      />
    </video>
  </v-container>
</template>


<script>
export default {
  name: "Univers",

  data: () => ({}),
  methods: {
    goUniversDrone: function () {
      this.$router.push({ path: "/drone" });
    },
    goUniversDeveloppement: function () {
      this.$router.push({ path: "/developpement" });
    },
  },
};
</script>

<style>
.v-application--wrap {
  min-height: 100vh !important; /*à laisser si calc non supporté*/
  min-height: calc(100 * var(--vh)) !important;
}

.btnGoUnivers .v-btn__content {
  max-width: 100%;
  white-space: break-spaces;
}
</style>

<style scoped>
#containerUnivers {
  overflow: hidden;
  height: 100vh; /*à laisser si calc non supporté*/
  height: calc(100 * var(--vh));
}

#rowUnivers {
}

#ET {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100vh; /*à laisser si calc non supporté*/
  height: calc(100 * var(--vh) - 56px);
}

#ET img {
  z-index: 1;
  position: relative;
  height: 15%;
}

.titleUnivers {
  /*font-size: 1.5rem !important;*/
}

@media (orientation: landscape) {
  .cardUnivers .v-image {
    height: 100vh; /*à laisser si calc non supporté*/
    height: calc(100 * var(--vh) - 56px);
    margin-top: 0px; /*à laisser si calc non supporté*/
    margin-top: calc(56px);
  }

  .colUnivers {
    flex: 0 0 50%;
    max-width: 50%;
    width: 100%;
    padding: 12px;
  }

  .titleUnivers {
    height: 100vh; /*à laisser si calc non supporté*/
    height: calc(100 * var(--vh) - 56px);
  }

  @media screen and (max-width: 900px) {
    .btnGoUnivers {
      font-size: 0.7rem !important;
    }
  }

  @media screen and (min-width: 1900px) {
    .btnGoUnivers {
      font-size: 1.5rem !important;
      height: 49px !important;
    }
  }
}

@media (orientation: portrait) {
  #rowUnivers {
    padding-top: 0px;
    padding-top: calc(56px);
  }

  .cardUnivers .v-image {
    height: 50vh; /*à laisser si calc non supporté*/
    height: calc(50 * var(--vh) - 28px);
  }

  #cardUniversDeveloppement {
    margin-top: 28px; /*à laisser si calc non supporté*/
    margin-top: calc(0px);
  }

  #cardUniversDrone .titleUnivers {
    margin-bottom: 28px; /*à laisser si calc non supporté*/
    margin-bottom: calc(0px);
  }

  #cardUniversDrone .titleUnivers {
    margin-bottom: 28px; /*à laisser si calc non supporté*/
    margin-bottom: calc(10px);
  }

  #cardUniversDeveloppement .titleUnivers {
    top: 0;
    position: absolute;
    margin-top: 28px; /*à laisser si calc non supporté*/
    margin-top: calc(10px);
    width: 100%;
  }

  #ET img {
    margin-top: 28px; /*à laisser si calc non supporté*/
    margin-top: calc(0px);
    margin-bottom: calc(10px);
  }

  .colUnivers {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    padding: 12px;
  }

  @media screen and (min-width: 450px) {
    .titleUnivers {
      font-size: 2.125rem !important;
    }
  }

  @media screen and (max-width: 449px) {
    .titleUnivers {
      font-size: 1.5rem !important;
    }
  }
}
</style>