<template>
  <div id="HomeDeveloppement">
    <Carroussel />
    <Realisations />
    <Services />
    <Tarifs />
  </div>
</template>

<script>
// @ is an alias to /src
import Carroussel from "@/components/developpement/Carroussel.vue";
import Realisations from "@/components/developpement/Realisations.vue";
import Services from "@/components/developpement/Services.vue";
import Tarifs from "@/components/developpement/Tarifs.vue";
import router from "@/router";

export default {
  name: "Home",
  components: {
    Carroussel,
    Realisations,
    Services,
    Tarifs,
  },
  metaInfo() {
    return {
      title: " - Prestation de développement",
      meta: [
        {
          name: "description",
          content:
            "Dev & Drone propose de la prestation dans le développement web principalement en régie, intégré à vos équipes. Les différents services que je propose sont de l'expertise, conseil & conception, du développement PHP, de la conception à la gestion de base de données et du développement front en HTML, CSS, JS.",
        },
        { property: "og:image", content: "https://dev-et-drone.fr/static/og-image-developpement.jpg" },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.dev-et-drone.fr/developpement"
        },
      ],
    };
  },
  data: () => ({
    fadeInElements: [],
  }),
  mounted() {
    let vm = this;

    setTimeout(function () {
      document.addEventListener("scroll", vm.scrollAnchor);
    }, 1000);

    this.fadeInElements = Array.from(
      document.getElementsByClassName("fade-in")
    );
    document.addEventListener("scroll", this.scrollFade);
  },
  destroyed() {
    document.removeEventListener("scroll", this.scrollAnchor);
    document.removeEventListener("scroll", this.scrollFade);
  },
  updated() {
    this.fadeInElements = Array.from(
      document.getElementsByClassName("fade-in")
    );
  },
  methods: {
    scrollAnchor() {
      document.getElementsByClassName("anchor_tags").forEach((item, index) => {
        if (router.options.isRouterScrollBehaviorActive()) return;
        var top = window.pageYOffset;
        var distance = top - item.offsetTop;
        var hash = item.id;
        // if you want a precise check then use distance===0
        var paddingChoice = window.innerHeight / 2;
        if (
          distance < paddingChoice &&
          distance > -paddingChoice &&
          router.currentRoute.hash != (hash ? "#" : "") + hash
        ) {
          if (hash == "") {
            router.replace(window.location.pathname);
            //window.location = window.location.origin + window.location.pathname;
            //window.location.href = window.location.pathname;
            // window.location.hash = hash;
          } else {
            router.replace(window.location.pathname + "#" + hash);
          }
        }
      });
    },
    scrollFade() {
      for (let i = 0; i < this.fadeInElements.length; i++) {
        const elem = this.fadeInElements[i];
        if (this.isElemVisible(elem)) {
          elem.style.opacity = "1";
          elem.style.transform = "scale(1)";
          this.fadeInElements.splice(
            i,
            1
          ); /*Pour jouer l'animation seulement une fois*/
        }
      }
    },
    isElemVisible(el) {
      const rect = el.getBoundingClientRect();
      const elemTop = rect.top + 150; /*buffer*/
      const elemBottom = rect.bottom;
      return elemTop < window.innerHeight && elemBottom >= 0;
    },
  },
};
</script>

<style scoped>
#HomeDeveloppement {
  overflow: hidden;
}
</style>